import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  VStack, Text, Heading,
  MenuButton, MenuList, Button, Box,
  Checkbox, CheckboxGroup,
  Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton,
  RangeSlider, RangeSliderThumb, RangeSliderTrack, RangeSliderFilledTrack
 } from '@chakra-ui/react';
 import { useContext } from 'react';
 import { UserContext } from '../../../Auth/UserContext';
 import SimpleBarReact from "simplebar-react";
 
 import "simplebar/src/simplebar.css";


const EconomyFilters = function(props) {
  const { user } = useContext(UserContext);
  const { sliderValues, handleSliderEvent, handleSlider, handleCheckboxes, sizes } = props;
  const premium = (user ? (user.premium ? true : false) : false);
  const freemium = (user ? true : false);
  const { heading, title, text, value, width } = sizes;
  return (
  <>
    <AccordionItem w={'100%'}>
      <AccordionButton size={heading}>
        <Heading className={'filter-heading'} flex='1' textAlign='left'  size={heading}>Economy</Heading>
        <AccordionIcon />
      </AccordionButton>
      <SimpleBarReact style={{maxHeight: '30vh'}} >
        <AccordionPanel>
          <VStack mb={2} >
            <Text as='u' paddingTop={'2'} fontSize={title}>Income</Text>
            <Text fontSize={text} className={'filter-desc'}>Minimum Wage</Text>
            <Text fontSize={value}>{sliderValues[26][0]} - {sliderValues[26][1]}</Text>
            <RangeSlider
              aria-label={['min', 'max']}
              isDisabled={!freemium}
              min={0}
              max={20}
              step={.50}
              colorScheme='gray'
              defaultValue={[0, 20]}
              onChange={(event) => {
                handleSlider(event, 26, 'economy-minimum_wage-^', handleSliderEvent)
              }}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} ></RangeSliderThumb>
              <RangeSliderThumb index={1} />
            </RangeSlider>
            <Text fontSize={text} className={'filter-desc'}>Median Household Income</Text>
              <Text fontSize={value}>{sliderValues[27][0]} - {sliderValues[27][1]}</Text>
            <RangeSlider
              aria-label={['min', 'max']}
              isDisabled={!freemium}
              min={50000}
              max={150000}
              step={1000}
              colorScheme='gray'
              defaultValue={[50000, 150000]}
              onChange={(event) => {
                handleSlider(event, 27, 'economy-med_household_income-^', handleSliderEvent)
              }}
            >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} ></RangeSliderThumb>
              <RangeSliderThumb index={1} />
            </RangeSlider>
            <Text fontSize={text} className={'filter-desc'}>Average Household Income</Text>
            <Text fontSize={value}>{sliderValues[28][0]} - {sliderValues[28][1]}</Text>
            <RangeSlider
              aria-label={['min', 'max']}
              isDisabled={!freemium}
              min={50000}
              max={150000}
              step={1000}
              colorScheme='gray'
              defaultValue={[50000, 150000]}
              onChange={(event) => {
                handleSlider(event, 28, 'economy-avg_household_income-^', handleSliderEvent)
              }}
            >
            <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} ></RangeSliderThumb>
              <RangeSliderThumb index={1} />
            </RangeSlider>
            <Text as='u' paddingTop={'2'} fontSize={title}>Unemployment:</Text>
            <Text fontSize={text} className={'filter-desc'}>Unemployment Rate</Text>
            <Text fontSize={value}>{sliderValues[29][0]} - {sliderValues[29][1]}%</Text>
            <RangeSlider
              aria-label={['min', 'max']}
              isDisabled={!freemium}
              min={0.0}
              max={15.0}
              step={.50}
              colorScheme='gray'
              defaultValue={[0.0, 15.0]}
              onChange={(event) => {
                handleSlider(event, 29, 'economy-unemployment_rate-^', handleSliderEvent)
              }}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} ></RangeSliderThumb>
              <RangeSliderThumb index={1} />
            </RangeSlider>
            <Text as='u' paddingTop={'2'} fontSize={title}>Hot Sector</Text>
            <CheckboxGroup>
              <Checkbox isDisabled={!freemium} onChange={handleCheckboxes} value="economy-economy_sector-tech" size={'sm'}>Tech</Checkbox>
              <Checkbox isDisabled={!freemium} onChange={handleCheckboxes} value="economy-economy_sector-energy" size={'sm'}>Energy</Checkbox>
              <Checkbox isDisabled={!freemium} onChange={handleCheckboxes} value="economy-economy_sector-manu" size={'sm'}>Manufacturing</Checkbox>
              <Checkbox isDisabled={!freemium} onChange={handleCheckboxes} value="economy-economy_sector-finance" size={'sm'}>Finance</Checkbox>
              <Checkbox isDisabled={!freemium} onChange={handleCheckboxes} value="economy-economy_sector-tourism" size={'sm'}>Tourism</Checkbox>
              <Checkbox isDisabled={!freemium} onChange={handleCheckboxes} value="economy-economy_sector-gov" size={'sm'}>Government</Checkbox>
              <Checkbox isDisabled={!freemium} onChange={handleCheckboxes} value="economy-economy_sector-natural*resources" size={'sm'}>Natural Resources</Checkbox>
              <Checkbox isDisabled={!freemium} onChange={handleCheckboxes} value="economy-economy_sector-agri" size={'sm'}>Agriculture</Checkbox>
            </CheckboxGroup>
          </VStack>
        </AccordionPanel>
      </SimpleBarReact>
    </AccordionItem>
  </>
  )
};

export { EconomyFilters };