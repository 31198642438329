import classNames from 'classnames'
import { Image, Box, Heading, Text, VStack, Button, Flex, Spacer, SkeletonText } from '@chakra-ui/react'
import { infoDescriptionSelection } from './infoDescriptionSelection';

// This function is called inside of the function buildCityCards below

const infoDescriptions = function(categoryObj, user) {
  const categoryArr = Object.entries(categoryObj);
  const displayArr = categoryArr.map((info) => {
    const description = infoDescriptionSelection(info[0], info[1])
    if (!description) {
      return;
    }
    const splitDesc = description.split(':')
    if (description.includes("INFORMATION FROM")) {
      return (<>
      <Box className={'spacer'} />
      <Text className={'information-from'}>
        {splitDesc[0]}
      </Text>
      </>)
    }
    if (user && !user.premium) {
      console.log("here")
    }
    return (<>
    <Heading className={'card-title __desc'} >
      {splitDesc[0]}
    </Heading>
    <Text className={'card-text __desc'}>
      {splitDesc[1]}
    </Text>
    </>
    )
  })
  return displayArr;
}

// This function is called inside of the function buildCityCards below
// wildly dependant on current category names
const displayHeader = function(initialHeading, size) {
  const headingArr = initialHeading.split('_');
  if (headingArr[0] === 'transportation') {
    return (<><Heading className={'category'} >Transport</Heading></>)
  }
  if (headingArr[0] === 'social') {
    return (<><Heading className={'category'} size={size} >Wellness</Heading></>)
  }
  if (headingArr[0] === 'cities') {
    return <Heading className={'category'} size={size} >General</Heading>
  }
  if (headingArr[0] === 'health') {
    return <Heading className={'category'} size={size} >Health</Heading>
  }
  if (headingArr[0] === 'demographics') {
    return <Heading className={'category'} size={size} >People</Heading>
  }
  const header = headingArr.map((word) => { 
    return word[0].toUpperCase() + word.substring(1); 
  }).join(" ");
  return (<Heading className={'category'} size={size} >{header}</Heading>);
}

// selected category is unavailable, the code is commented out below line 165
//  would set a category name and display only that categories contents

const buildCityCards = function(user, city, cityObject, selectedCategory, onClick, onClose) {
  const categoryArr = cityObject[city];

  if (categoryArr[1]) {
    const displayInfo = categoryArr.map((categoryObj) => {
      let category = Object.keys(categoryObj)[0];

      // currently infoDescriptions is above, starting line 5
      const displayInfo = infoDescriptions(categoryObj[category], user);
      let checkForInfo = false;
      for (const line of displayInfo) {
        if (line !== undefined) {
          checkForInfo = true;
        }
      }
      if (!checkForInfo) {
        console.log(category)
        return (<></>)
      }
      const header = displayHeader(category);
      let icon = 'images/'
      switch (category) {
        case 'demographics':
          icon = icon + 'people-together.png';
          break;
        case 'social_civil_capital':
          icon = icon + 'healthy-lifestyle.png';
          break;
        case 'transportation_infrastructure':
          icon = icon + 'map.png';
          break;
        case 'cities':
          icon = icon + 'architecture-and-city.png';
          break;
        case 'housing':
          icon = icon + 'house.png';
          break;
        case 'health_care':
          icon = icon + 'healthcare.png';
          break;
        case 'economy':
          icon = icon + 'job.png';
          break;
        case 'amenities':
          icon = icon + 'architecture-and-city.png';
          break;
        case 'weather':
          icon = icon + 'cloudy.png';
          break;
      }
      return (
        <>
        <Box className="InfoCards-cards">
          <VStack w={'100%'}>
            <Box className={'InfoCards-top'}>
              <Flex className={'card-top __flex'} >
                <VStack className={'card-top __header'} >{header}</VStack>
                <Spacer />
                <Image className={'card-top __icon'} src={icon} alt='Open' />
              </Flex>
            </Box>
            <Box className={'InfoCards-bottom'}>
              <Box className={'card-bottom __box'} >
                <Text className={'card-bottom __text'} >
                  {displayInfo}
                  {(category !== "cities" && user && !user.premium) &&
                  <>
                    <SkeletonText paddingInline={'1em'} noOfLines={6} startColor={'grey'} endColor={'grey'}/>
                    <Box className={'premium'}> Premium Info
                    </Box>
                  </>}
                </Text>
              </Box>
            </Box>
          </VStack>
        </Box>
        </>
        );
    });

    return (
      <>
      {displayInfo}
      </>
      )
  }
  return (
  <>
  <Box className="InfoCards-cards" minH="16em" minW='15vw' maxW='50vw' borderWidth='1px' borderRadius={'5px'}>
    <Heading>Missing City Info</Heading>
  </Box>
  </>
  )

};

export {buildCityCards, displayHeader, infoDescriptions};


// currently unused code for displaying only one category, needs work
// would be placed at line 69 to begin integrating

  // if (selectedCategory) {
  //   const categoryInfo = [];
  //   for (const category of categoryArr) {
  //     if (category[selectedCategory]) {
  //       categoryInfo.push(category[selectedCategory]);
  //     }
  //   }
  //   const header = displayHeader(selectedCategory)
  //   if (categoryInfo[0]) {
  //     const displayInfo = infoDescriptions(categoryInfo[0]);
  //     return  (
  //       <>
  //         <Box className="InfoCards-cards" minH="25em" minW='100em' overflow='hidden'>
  //           <VStack w={'25em'}>
  //             <Box borderRadius={'3xl'} borderWidth='2px' borderColor='black' w={'100%'} h={'6em'}>
  //               <VStack>{header}</VStack>
  //             </Box>
  //             <VStack spacing={6}>{displayInfo}</VStack>
  //             <Box justifyContent={'right'} w={"100%"}>
  //               <Button onClick={onClose}> Close</Button>
  //             </Box>
  //           </VStack>
  //         </Box>
  //       </>
  //       )
  //   }// add links for additional information, such as global stats or city site or hiking near this city
  //   return(<></>)
  //}