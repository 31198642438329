import {useParams} from 'react-router-dom'

import { Box, Heading } from '@chakra-ui/react'
import InfoCards from './InfoCards'
import InfoAccordion from './InfoAccordion'

import '../../styles/CityDashboard.scss'
import { grabCityInfo } from '../../helpers/grabCityInfo'


const CityDashboard = function(props) {
  const params = useParams();
  const cityObject = grabCityInfo(props.state, params.city);
  return (
  <div className="CityDashboard">
    <div className="CityDashboard-top">
      <div className="city-banner-container">
        <div className="city-banner-flex">
          <Heading>{params.city}</Heading>
        </div>
      </div>
      <div className="map-container">
        <Box className="map-flex" minH="200" w="80" borderWidth='1px' borderRadius="lg">
          Map
        </Box>
      </div>
    </div>
    <div className="CityDashboard-bottom">
      <div className="InfoCards-container">
        <InfoCards cityObject={cityObject} cityName={params.city}/>
      </div>
      <div className="InfoAccordion-container">
        <InfoAccordion cityObject={cityObject} cityName={params.city}/>
      </div>
    </div>
  </div>)
}

export default CityDashboard;