import {
  VStack, Text, Heading,
  MenuButton, MenuList,
  Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton,
  RangeSlider, RangeSliderThumb, RangeSliderTrack, RangeSliderFilledTrack, Button
 } from '@chakra-ui/react';
 import { useContext } from 'react';
 import { UserContext } from '../../../Auth/UserContext';
 import SimpleBarReact from "simplebar-react";
 
 import "simplebar/src/simplebar.css";


const DemographicsFilters = function(props) {
  const { user } = useContext(UserContext);
  const { sliderValues, handleSliderEvent, handleSlider, sizes } = props;
  const premium = (user ? (user.premium ? true : false) : false);
  const freemium = (user ? true : false);
  const { heading, title, text, value } = sizes;

  
  return (
  <>
    <AccordionItem w={'100%'}>
    <AccordionButton h={'4vh'} size={heading} >
      <Heading className={'filter-heading'} flex='1' textAlign='left' size={heading}>Demographics</Heading>
    <AccordionIcon />
    </AccordionButton>
    <SimpleBarReact style={{maxHeight: '30vh'}}>
    <AccordionPanel>
            <VStack mb={2}>
            <Text as='u' fontSize={title}>General Population</Text>
              <Text fontSize={text} className={'filter-desc'}>City Population</Text>
              <Text fontSize={value}>{sliderValues[30][0]} - {sliderValues[30][1]}</Text>
              <RangeSlider
                aria-label={['min', 'max']}
                isDisabled={!freemium}
                min={0}
                max={3000000}
                step={50000}
                colorScheme='gray'
                defaultValue={[0, 3000000]}
                onChange={(event) => {
                  handleSlider(event, 30, 'cities-population_city-^', handleSliderEvent)
                }}
              >
                <RangeSliderTrack>
                  <RangeSliderFilledTrack />
                </RangeSliderTrack>
                <RangeSliderThumb index={0} ></RangeSliderThumb>
                <RangeSliderThumb index={1} />
              </RangeSlider>
              <Text fontSize={text} className={'filter-desc'}>Metro Population</Text>
              <Text fontSize={value}>{sliderValues[31][0]} - {sliderValues[31][1]}</Text>
              <RangeSlider
                aria-label={['min', 'max']}
                isDisabled={!freemium}
                min={0}
                max={8000000}
                step={50000}
                colorScheme='gray'
                defaultValue={[0, 8000000]}
                onChange={(event) => {
                  handleSlider(event, 31, 'cities-population_urban-^', handleSliderEvent)
                }}
              >
                <RangeSliderTrack>
                  <RangeSliderFilledTrack />
                </RangeSliderTrack>
                <RangeSliderThumb index={0} ></RangeSliderThumb>
                <RangeSliderThumb index={1} />
              </RangeSlider>
              <Text fontSize={text} className={'filter-desc'}>Population Density</Text>
              <Text fontSize={value}>{sliderValues[32][0]} - {sliderValues[32][1]} km²</Text>
              <RangeSlider
                aria-label={['min', 'max']}
                isDisabled={!freemium}
                min={0}
                max={7500}
                step={50}
                colorScheme='gray'
                defaultValue={[0, 7500]}
                onChange={(event) => {
                  handleSlider(event, 32, 'demographics-population_density-^', handleSliderEvent)
                }}
              >
                <RangeSliderTrack>
                  <RangeSliderFilledTrack />
                </RangeSliderTrack>
                <RangeSliderThumb index={0} ></RangeSliderThumb>
                <RangeSliderThumb index={1} />
              </RangeSlider>
              <Text fontSize={text} className={'filter-desc'}>Median Age</Text>
              <Text fontSize={value}>{sliderValues[33][0]} - {sliderValues[33][1]} yrs old</Text>
              <RangeSlider
                aria-label={['min', 'max']}
                isDisabled={!freemium}
                min={25}
                max={75}
                step={1}
                colorScheme='gray'
                defaultValue={[25, 75]}
                onChange={(event) => {
                  handleSlider(event, 33, 'demographics-med_age-^', handleSliderEvent)
                }}
              >
                <RangeSliderTrack>
                  <RangeSliderFilledTrack />
                </RangeSliderTrack>
                <RangeSliderThumb index={0} ></RangeSliderThumb>
                <RangeSliderThumb index={1} />
              </RangeSlider>
            <Text as='u' pt={'2'} fontSize={title}>Population Statistics</Text>
              <Text fontSize={text} className={'filter-desc'}>Immigrant Population</Text>
              <Text fontSize={value}>{sliderValues[34][0]} - {sliderValues[34][1]}%</Text>
              <RangeSlider
                aria-label={['min', 'max']}
                isDisabled={!freemium}
                min={0.0}
                max={75.0}
                step={5}
                colorScheme='gray'
                defaultValue={[0.0, 75.0]}
                onChange={(event) => {
                  handleSlider(event, 34, 'demographics-population_percent_immigrant-^', handleSliderEvent)
                }}
              >
                <RangeSliderTrack>
                  <RangeSliderFilledTrack />
                </RangeSliderTrack>
                <RangeSliderThumb index={0} ></RangeSliderThumb>
                <RangeSliderThumb index={1} />
              </RangeSlider>
              <Text fontSize={text} className={'filter-desc'}>Francophone Population</Text>
              <Text fontSize={value}>{sliderValues[35][0]} - {sliderValues[35][1]}%</Text>
              <RangeSlider
                aria-label={['min', 'max']}
                isDisabled={!freemium}
                min={0.00}
                max={100.00}
                step={5}
                colorScheme='gray'
                defaultValue={[0.00, 100.00]}
                onChange={(event) => {
                  handleSlider(event, 35, 'demographics-population_percent_francophone-^', handleSliderEvent)
                }}
              >
                <RangeSliderTrack>
                  <RangeSliderFilledTrack />
                </RangeSliderTrack>
                <RangeSliderThumb index={0} ></RangeSliderThumb>
                <RangeSliderThumb index={1} />
              </RangeSlider>
            </VStack>
    </AccordionPanel>
    </SimpleBarReact>
    </AccordionItem>
  </>
  )
};

export { DemographicsFilters };