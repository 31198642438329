import {Marker} from 'react-map-gl'
import {TriangleDownIcon} from '@chakra-ui/icons'
import {VStack, Box, Flex, Image, Text, Heading, Spacer} from '@chakra-ui/react'
import { renderMatches } from 'react-router';
import classNames from 'classnames'
import { useState } from 'react';

const makeMarkers = function(markerArg, handleMarkerSelect, handleMarkerHover, removeHandleMarkerHover, selectCity, hoverCity, handleCardClick) {
  const {cities, filters} = markerArg;

  const markerArray = cities.map(city => {
    // const cityCardName = classNames();
    // const cityMarkerName = classNames();
    const cityCardKey = city.id + cities.length;
    const latitude = Number(city.latitude);
    const longitude = Number(city.longitude)
    const filterRef = filters[city.name].filterRef;
    const filterActive = filters[city.name].active;
    const filterInfo = filters[city.name].info.map(info => {
      return (<Text fontSize='sm' noOfLines={1}>{info}</Text>)
    });
    return (
    <>
    {filterActive === 0 &&
    <>
    <Marker
      key={city.id}
      longitude={longitude}
      latitude={latitude}
      offsetLeft={-14}
      offsetTop={-33}
      >
        <img style={{maxHeight: '40px'}} onClick={handleMarkerSelect} onMouseEnter={handleMarkerHover} onMouseLeave={removeHandleMarkerHover} className={city.name} src='images/placeholder-2.png' />
    </Marker>
    {(selectCity === city.name || hoverCity === city.name) &&
    <Marker
      key={cityCardKey}
      value={city.name}
      longitude={longitude}
      latitude={latitude}
      offsetLeft={-150}
      offsetTop={-230}
      style={{zIndex: 400}}
      >
      <Box className={"marker-card"} >
        <Box whiteSpace={'normal'} p={'15px'} h='160px' w='72' borderRadius='lg' noOfLines={4}>
          <VStack >
            <Heading className={"city-name"} fontSize={'24'} >{city.name}</Heading>
            <Heading textAlign={'center'} fontSize={'16'}>{city.province}</Heading>
            {filterInfo}
          </VStack>
        </Box>
        <Flex className={"bottom"}>
          <Flex className={"button image-hover"} variant={'unstyled'} onClick={() => handleCardClick(city.name)} fontSize='xs'>
            <Image className={"icon"} src={'/images/apps-free-icon-font.png'} alt='Open City Info' />
          </Flex>
        </Flex>
      </Box >
    </Marker>
    }
    </>
    }
    </>)
  });
  return markerArray;
}

export {makeMarkers}