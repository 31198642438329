import { useContext, useState } from "react"
import { accountLoginProps, accountRegisterProps, accountSettingsProps } from "../../helpers/accountForm"
import { useFormEnabler } from "../../hooks/useFormEnabler"
import { Button } from "@chakra-ui/react"
import { UserContext } from "../../Auth/UserContext"
const { ModalForm } = require("./ModalForm")



const AccountDesktop = function() {
  const { user, setUser } = useContext(UserContext)
  const { handleCancel, handleSubmit } = useFormEnabler(user, setUser);
  const Login = function() {
    const accountProps = accountLoginProps(undefined, handleCancel, handleSubmit);
    return (<ModalForm accountProps={accountProps} />)
  }
  const Register = function() {
    const accountProps = accountRegisterProps(undefined, handleCancel, handleSubmit);
    return (<ModalForm accountProps={accountProps} />)
  }
  if (user.username ) {
    return (<></>);
  }
  return (
    <>
      <Button borderRadius={'5'} mr={'3'}>
        <Login />
      </Button>

      <Button>
        <Register borderRadius={'5'} />
      </Button>
    </>
  )
}
const SettingsDesktop = function(props, ref) {
  const { user, setUser } = useContext(UserContext)
  const { disable, handleCancel, handleSubmit } = useFormEnabler(user, setUser);
  const accountProps = accountSettingsProps(disable, handleCancel, handleSubmit)
  return (<ModalForm accountProps={accountProps}/>)
}

export { AccountDesktop, SettingsDesktop };