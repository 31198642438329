
import {
  Button,
  Modal, ModalContent, ModalOverlay, ModalBody, ModalHeader, ModalFooter, useDisclosure,
  FormControl, FormLabel, Input, InputGroup, InputRightElement,
  Box, Spinner, Alert, Text, HStack
} from '@chakra-ui/react';

import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { UserContext } from '../../Auth/UserContext';
import { Auth } from "aws-amplify";

const ResetPassword = function(props) {
  const { setForm } = props;
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ values, setValues ] = useState({formOne: '', formTwo: '', formThree: '', formFour: ''});
  const [ alert, setAlert ] = useState('');
  const [ pending, setPending ] = useState(false);
  const [ validate, setValidate ] = useState(false);
  const [ validationCode, setValidationCode ] = useState('');
  const [ visibility, setVisibility ] = useState({
    formTwo: false,
    formThree: false
  });


  const handleChange = function(event, form) {
    const value = event.target.value;
    setValues(prev => {
      return {...prev, [form]: value}
    })
  }
  const handleCancel = async() => {
    try {
      setValues({formOne: '', formTwo: '', formThree: '', formFour: ''});
      setAlert('');
      setVisibility({
        formTwo: false,
        formThree: false
      });
    }
    catch {
      setValues({formOne: '', formTwo: '', formThree: '', formFour: ''});
      setAlert('');
      setVisibility({
        formTwo: false,
        formThree: false
      });

    }

  }
  const validateCode = async(validationCode, values) => {
    if (validationCode && values.formTwo === values.formThree && values.formTwo.length > 7) {
      try {
        await Auth.forgotPasswordSubmit(values.formOne, validationCode, values.formTwo)
          .then((res) => {
            console.log(res)
            setAlert({alert: "success", msg: "Password Reset"})
            setPending(false);
            setValidate(false);
            setTimeout(() => {
              setForm(false);
            }, 500)
          })
      }
      catch (err) {
        setAlert({alert: "warning", msg: "Invalid Validation Code"})
      }
    }
    if (!validationCode) {
      setAlert({alert: "warning", msg: "Please Enter Validation Code"})
    }
    if (!values.formTwo || !values.formThree) {
      setAlert({alert: "warning", msg: "Please Enter and Confirm Your Password"})
    }
    if (values.formTwo !== values.formThree) {
      setAlert({alert: "warning", msg: "The Passwords Must Match"})
    }
    if (values.formTwo.length < 8) {
      setAlert({alert: "warning", msg: "Passwords Must Be 8 Or More Characters Long"})
    }
  }
  const onSubmit = async(values) => {
    if (values.formOne) {
      try {
        await Auth.forgotPassword(values.formOne)
          .then((res) => {
            setAlert({alert: "warning", msg: "Please check your email for validation code"});
            setValidate(true);
          })

      } catch (err) {
        console.log(err)
        setAlert({alert: "warning", msg: "Invalid Email"});
        setPending(false);
      }
    } else {
      setAlert({alert: "error", msg: "Please enter your email"})
    }
  }
  return (
  <>
  <ModalHeader>Reset Password</ModalHeader>
        <ModalBody >
          <FormControl p={'3'} >
              <Input disabled={validate} value={values.formOne} onChange={event => {handleChange(event, 'formOne')}} placeholder={"Email"} />
          </FormControl>
          {validate && <>
          <FormControl p={'3'}>
              <InputGroup >
                <Input type={(visibility.formTwo ? 'text' : 'password')} value={values.formTwo} onChange={event => {handleChange(event, 'formTwo')}} placeholder={"Password"} />
                <InputRightElement w={'14'} >
                  <Button
                    variant={'outline'}
                    borderRightRadius={"6px"}
                    borderLeftRadius={"0px"}
                    onClick={(e) => {setVisibility(prev => {
                    return {...prev, formTwo: !visibility.formTwo};
                  })}}>
                    {(visibility.formTwo ? 'Hide' : 'Show')}
                  </Button>
                </InputRightElement>
              </InputGroup>
          </FormControl>
          <FormControl p={'3'}>
              <InputGroup >
                <Input type={(visibility.formTree ? 'text' : 'password')} value={values.formThree} onChange={event => {handleChange(event, 'formThree')}} placeholder={"Confirm Password"} />
                <InputRightElement w={'14'} >
                  <Button
                    variant={'outline'}
                    borderRightRadius={"6px"}
                    borderLeftRadius={"0px"}
                    onClick={(e) => {setVisibility(prev => {
                    return {...prev, formTwo: !visibility.formThree};
                  })}}>
                    {(visibility.formTwo ? 'Hide' : 'Show')}
                  </Button>
                </InputRightElement>
              </InputGroup>
          </FormControl>
          <FormControl p={'3'}>
              <Input value={validationCode} onChange={e => setValidationCode(e.target.value)} placeholder={"Please enter your validation code"} />
            </FormControl>
          </>}
        </ModalBody>
        <ModalFooter>
          <HStack w={'20vw'} spacing={'4'}>
            <Box w={'80%'}>
              {alert &&
              <Alert status={alert.alert}> {alert.msg} </Alert>}
            </Box>
            <Box w='20%' h={'100%'} pr={'3'} pl={'3'} >
              {pending &&
              <Spinner />}
            </Box>
          </HStack>
          { validate ? (
          <Button
            mr={'2'}
            padding={'2'}
            variant={'outline'}
            borderRadius={'5'}
            onClick={() => {
              validateCode(validationCode, values)
            }} >
              Reset
          </Button>
          ):(
          <Button
            mr={'2'}
            padding={'2'}
            variant={'outline'}
            borderRadius={'5'}
            onClick={() => {
              onSubmit(values)
            }} >Send Email
          </Button>
          )}
          <Button
            mr={'3'}
            padding={'2'}
            variant={'outline'}
            borderRadius={'5'}
            onClick={() => {
              handleCancel();
              navigate("/");
              onClose();
            }} >Cancel</Button>
        </ModalFooter>
  </>)
};

export default ResetPassword;