//@ts-check

import { Auth } from "aws-amplify";
import axios from "axios"
import { useState } from "react"


const useFormEnabler = function(currentUser, setUser) {
  const [ disable, setDisable ] = useState(true)
  const toggleForm = function(set) {
    if (set !== undefined) {
      setDisable(() => set)
      return;
    }
    setDisable(() => !disable)
  }

  const updatePrem = async(user) => {
    return await Auth. updateUserAttributes(user, {

    });
  };

  const handleSubmit = async(values, type, premium, setAlert, setPending, setValidate) => {
    if (type === "Login") {
      if (values.formOne && values.formTwo) {
        return await Auth.signIn(values.formOne, values.formTwo)
          .then((user) => {
            if (user.username ) {
              const userObj = { username: user.username, premium: undefined }
              setUser({...userObj});
              setAlert({alert: "success", msg: "Login Successful"});
              setPending(false);
              return;
            }
          })
          .catch(err => {
            if (err.name === "UserNotConfirmedException") {
              setAlert({alert: "warning", msg: "User Not Validated, please fill out form again"});
              setValidate(true);

            } else {
              console.log(err)
              setAlert({alert: "error", msg: "User Does Not Exist"});
              setPending(false);
              return;
            }
          });
      }
      setAlert({alert: "warning", msg: "Values Missing"});
      setPending(false);
      return;
    }
    if (type === "Create") {
      if(values.formOne === values.formTwo && values.formFour === values.formThree) {
        console.log("Submit the New Account Form")
        const user = {
          email: values.formOne,
          password: values.formThree,
        }
        if (premium !== "") {
          console.log("need to add premium here")
        }
        try {
          await Auth.signUp(values.formOne, values.formThree)
            .then((res) => {
              setAlert({alert: "warning", msg: "Please check your email for validation code"});
              setValidate(true);
            })

        } catch (err) {
          setAlert({alert: "warning", msg: "Invalid Email or Password"});
          setPending(false);
        }
      } else {
        if (!values.formOne || !values.formTwo || !values.formThree) {
          setAlert({alert: "warning", msg: "Please Fill out the Form"});
          setPending(false);
          return;
        }
        if (values.formOne !== values.formTwo) {
          setAlert({alert: "warning", msg: "Email doesn't match"});
          setPending(false);
          return;
        }
        setAlert({alert: "warning", msg: "Password doesn't match"});
        setPending(false);
        return;
      }
    }
    if (type === "Save" || type === "Edit") {

      toggleForm();
      if (disable) {
        console.log("Edit the Account Settings")
        setAlert({alert: "info", msg: "Edit Enabled"});
        setPending(false);
        return;
      }
      if(values.formTwo && values.formTwo === values.formThree) {
        console.log("Submit the Edit form")
        const user = {
          email: values.formOne,
          password: values.formTwo,
          oldPassword: values.formFour
        }
        if (!values.formOne) {
          user.email = currentUser[0].email;
        }
        if (premium !== "") {
          user.premium = premium;
        }
        axios.post(`user/${currentUser[0].id}`, user)
          .then((res) => {
            if (res.data === "Form Invalid") {
              setAlert({alert: "error", msg: "No Spaces Allowed"});
              setPending(false);
              return;
            }
            if (res.data === "Password Invalid") {
              setAlert({alert: "error", msg: "Please retry your password"});
              setPending(false);
              return;
            }
            setAlert({alert: "success", msg: "Settings Saved"});
            setPending(false);
            return;
          })
      } else {
        setAlert({alert: "warning", msg: "Password doesn't match"});
        setPending(false);
        return;
      }
    }
  }
  const handleCancel = function() {
    toggleForm(true);
  }
  return { disable, toggleForm, handleCancel, handleSubmit }
}

export { useFormEnabler }