import useViewport from '../../hooks/useViewport';
import '../../styles/Header.scss'
import Account from '../Account/Index'
import SearchBar from './SearchBar'

const Header = function(props) {
  const { cities } = props;
  const { width, height } = useViewport();
  return (
  <div className="Header">
    <div className="Header-container">
      <div className="Header-flex">
        <div className="Header-top">
          <div className="logo-container">
            <div className="logo-flex">
              Logo
            </div>
          </div>
          <div className="Header-top-right">
            <div className="create-pdf-container">
              <div className="create-pdf-flex">
              </div>
            </div>
            <div className="account-container">
              <div className="account-flex">
                <Account />
              </div>
            </div>
          </div>
        </div>
        <div className="Header-bottom">
          <div className="banner-container">
            <div className="banner-flex">
              <SearchBar cities={cities}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>);
}

export default Header;