/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCity = /* GraphQL */ `
  mutation CreateCity(
    $input: CreateCityInput!
    $condition: ModelCityConditionInput
  ) {
    createCity(input: $input, condition: $condition) {
      id
      name
      nickname
      province
      population_city
      population_urban
      population_year
      population_density
      sponsored
      med_age
      summary
      longitude
      latitude
      population_percent_immigrant
      population_percent_francophone
      number_french_school_program
      language_first
      language_second
      number_pool
      number_parks
      number_museum
      number_movie_theatre
      number_bar_restaurant
      number_festivals
      avg_winter_temp
      avg_summer_temp
      activities
      minmium_wage
      avg_household_income
      med_household_income
      unemployment_rate
      unemployment_year
      economy_sector
      active_health_system
      number_hospitals
      number_children_hospitals
      number_beds
      number_sick_paid
      number_sick_unpaid
      clinic_population_density
      library_density
      groceries_density
      avg_crime_rate
      avg_house_price
      avg_rent_studio
      avg_rent_bed_1
      avg_rent_bed_2
      congestion_level
      avg_daily_transport_time
      bus_lines
      rail_lines
      km_rail
      domestic_connections
      international_connections
      france_flights
      createdAt
      updatedAt
    }
  }
`;
export const updateCity = /* GraphQL */ `
  mutation UpdateCity(
    $input: UpdateCityInput!
    $condition: ModelCityConditionInput
  ) {
    updateCity(input: $input, condition: $condition) {
      id
      name
      nickname
      province
      population_city
      population_urban
      population_year
      population_density
      sponsored
      med_age
      summary
      longitude
      latitude
      population_percent_immigrant
      population_percent_francophone
      number_french_school_program
      language_first
      language_second
      number_pool
      number_parks
      number_museum
      number_movie_theatre
      number_bar_restaurant
      number_festivals
      avg_winter_temp
      avg_summer_temp
      activities
      minmium_wage
      avg_household_income
      med_household_income
      unemployment_rate
      unemployment_year
      economy_sector
      active_health_system
      number_hospitals
      number_children_hospitals
      number_beds
      number_sick_paid
      number_sick_unpaid
      clinic_population_density
      library_density
      groceries_density
      avg_crime_rate
      avg_house_price
      avg_rent_studio
      avg_rent_bed_1
      avg_rent_bed_2
      congestion_level
      avg_daily_transport_time
      bus_lines
      rail_lines
      km_rail
      domestic_connections
      international_connections
      france_flights
      createdAt
      updatedAt
    }
  }
`;
export const deleteCity = /* GraphQL */ `
  mutation DeleteCity(
    $input: DeleteCityInput!
    $condition: ModelCityConditionInput
  ) {
    deleteCity(input: $input, condition: $condition) {
      id
      name
      nickname
      province
      population_city
      population_urban
      population_year
      population_density
      sponsored
      med_age
      summary
      longitude
      latitude
      population_percent_immigrant
      population_percent_francophone
      number_french_school_program
      language_first
      language_second
      number_pool
      number_parks
      number_museum
      number_movie_theatre
      number_bar_restaurant
      number_festivals
      avg_winter_temp
      avg_summer_temp
      activities
      minmium_wage
      avg_household_income
      med_household_income
      unemployment_rate
      unemployment_year
      economy_sector
      active_health_system
      number_hospitals
      number_children_hospitals
      number_beds
      number_sick_paid
      number_sick_unpaid
      clinic_population_density
      library_density
      groceries_density
      avg_crime_rate
      avg_house_price
      avg_rent_studio
      avg_rent_bed_1
      avg_rent_bed_2
      congestion_level
      avg_daily_transport_time
      bus_lines
      rail_lines
      km_rail
      domestic_connections
      international_connections
      france_flights
      createdAt
      updatedAt
    }
  }
`;
export const createPremium = /* GraphQL */ `
  mutation CreatePremium(
    $input: CreatePremiumInput!
    $condition: ModelPremiumConditionInput
  ) {
    createPremium(input: $input, condition: $condition) {
      id
      premium
      username
      email
      createdAt
      updatedAt
    }
  }
`;
export const updatePremium = /* GraphQL */ `
  mutation UpdatePremium(
    $input: UpdatePremiumInput!
    $condition: ModelPremiumConditionInput
  ) {
    updatePremium(input: $input, condition: $condition) {
      id
      premium
      username
      email
      createdAt
      updatedAt
    }
  }
`;
export const deletePremium = /* GraphQL */ `
  mutation DeletePremium(
    $input: DeletePremiumInput!
    $condition: ModelPremiumConditionInput
  ) {
    deletePremium(input: $input, condition: $condition) {
      id
      premium
      username
      email
      createdAt
      updatedAt
    }
  }
`;
