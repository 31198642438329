
import { NavLink, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import Filters from "../Home/Filters/Filters";
import CityDashboard from '../CityDashboard/Index';
import CityList from '../Home/CityList';
import { HiddenDrawerContext } from './HiddenDrawerContext';
import { OpenDrawerContext } from './OpenDrawerContext';
import '../../styles/Drawer.scss'

import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css";

import { Spacer, VStack, Image, Button, Box, HStack, StackDivider, Divider, Flex, Tooltip } from '@chakra-ui/react';
import useViewport from '../../hooks/useViewport';
import { UserContext } from '../../Auth/UserContext';

// context custom hook, remove-able

const useDrawerContext = function() {
  const [ hidden, setHidden ] = useState(HiddenDrawerContext);
  const [ open, setOpen ] = useState(OpenDrawerContext);

  return { open, setOpen, hidden, setHidden };
}

// Custom hook to hold drawer states together, currently in same folder, remove-able
// Each setOpen and setHidden is very intentionally called, essentially the hidden has to be
// set to false whenever the drawer is open or in motion while open is called to start the motion
// the last two useEffects have to do with the bottom drawer opening partially when city info is called

const useEitherDrawer = function() {
  const { open, setOpen } = useContext(OpenDrawerContext);
  const { hidden, setHidden } = useContext(HiddenDrawerContext);
  const navigate = useNavigate();

  const [ partialOpen, setPartialOpen ] = useState(false);
  const [ lastCityParams, setLastCityParams ] = useState();

  const onToggleNav = () => {
    try {
      setOpen(prev => {
        return {nav: !open.nav, data: false}
      })
      setHidden(prev => {
        return {nav: !hidden.nav, data: true}
      });
    }
    catch {
      setOpen(prev => {
        return {nav: true, data: false}
      })
      setHidden(prev => {
        return {nav: false, data: true}
      });
    }
  };
  const onToggleData = () => {
    try {
      setHidden(prev => {
        return {nav: true, data: open.data}
      });
      setOpen(prev => {
        return {nav: false, data: !open.data}
      })
    }
    catch {
      setOpen(prev => {
        return {nav: false, data: true}
      })
      setHidden(prev => {
        return {nav: true, data: false}
      });
    }
  };
  const onStart = () => {
    setHidden(prev => {
      return {nav: false, data: false}
    });
  }
  const onHomeClick = () => {
    navigate('/');
        
    setOpen(prev => {
      return {nav: true, data: false}
    })
    setHidden(prev => {
      return {nav: false, data: true}
    });
  }
  const onCitiesClick = () => {
    navigate('/');
        
    setOpen(prev => {
      return {nav: false, data: !open.data}
    })
    setHidden(prev => {
      return {nav: true, data: !hidden.data}
    });
  }
  const onFilterClick = () => {
    setOpen(prev => {
      return {nav: true, data: false}
    })
    setHidden(prev => {
      return {nav: false, data: true}
    });
  }

  const params = useParams();
  useEffect(() => {
    if (params.city && !open.data) {
      setOpen(prev => {
        return {nav: false, data: false}
      });
      setHidden(prev => {
        return {nav: true, data: false}
      });
    }
  }, [params.city]);


  useEffect(() => {
    if (open.data === false && lastCityParams !== params.city && params.city) {
      setPartialOpen(true);
      console.log(lastCityParams, partialOpen)
    }
    if (lastCityParams !== params.city) {
      setLastCityParams(params.city)
      return;
    }
    if (open.data === true || open.nav === true) {
      setPartialOpen(false);
    }
  }, [params.city, open])

  return { onToggleNav, onToggleData, onStart, hidden, setHidden, open, setOpen, onCitiesClick, onHomeClick, onFilterClick, partialOpen }
}

const NavDrawer = function(props) {
  const { handleCheckboxes, handleSliderEvent, resetFilter, resetHeuristicKey} = props;
  const {open, hidden, onStart, onToggleNav, onCitiesClick, onHomeClick, onFilterClick} = useEitherDrawer();
  const { width, height } = useViewport();
  const [ openWidth, setOpenWidth ] = useState(width > 1100 ? "16vw" : "30vw");
  const [ closedWidth, setClosedWidth ] = useState(width > 1100 ? "6vw" : "10vw");
  const [ viewHeight, setViewHeight ] = useState(height);
  useEffect(() => {
    setOpenWidth(width > 1100 ? "16vw" : "30vw");
    setClosedWidth(width > 1100 ? "6vw" : "10vw");
    setViewHeight(height);
  }, [width, height])

  return (
    <>
      <motion.div
        id={`nav-drawer`}
        className={"NavDrawer-open"}
        hidden={hidden.nav}
        initial={false}
        onAnimationStart={() => onStart()}
        animate={{ width: open.nav ? openWidth : 0 }}
        transition={{ type: 'ease'}}
        style={{
          hidden: hidden.nav,
          height: viewHeight,
          top: '0',
          zIndex: 700
        }}
      >
        <VStack h={'90%'} pt={'4'} divider={<StackDivider />}>
          <VStack w={openWidth} >
            <HStack w={openWidth}>
              <Spacer />
              <Box float={'top'} variant={'unstyled'} borderRadius={'5'} id={'nav-toggle'} size={'md'} onClick={onToggleNav} >
                  <Image className={'icon image-hover'} transform={'rotate(180deg)'} maxH={'5vh'} maxW={'5vh'} src='images/fast-forward-button.png' alt='Close' />
              </Box>
            </HStack>
            <Button className={'Nav-links'} pt={'3'} color={'black'} variant={'link'} w={'80%'} onClick={() => onHomeClick()} justifyContent={'space-evenly'}>
              <Image maxH={'5vh'} maxW={'5vh'} src='images/home.png' alt='Home' />
              <Spacer />
              <span className={'Nav-link-text'} >
                Home
              </span>
              <Spacer />
            </Button>
            <Button className={'Nav-links'} color={'black'} variant={'link'} w={'80%'} onClick={() => onCitiesClick()} justifyContent={'space-evenly'}>
              <Image maxH={'5vh'} maxW={'5vh'} src='images/skyline.png' alt='Cities' />
              <Spacer />
              <span className={'Nav-link-text'} >
                Cities Info
              </span>
              <Spacer />
            </Button>
          </VStack>
            <Filters key={resetHeuristicKey} handleCheckboxes={handleCheckboxes} handleSliderEvent={handleSliderEvent} />
        </VStack>
      </motion.div>
      <div
        className={"NavDrawer-closed"}
        style={{
          height: viewHeight,
          width: closedWidth
        }}
        >
          <VStack pt={'4'}>
            <Box variant={'unstyled'} borderRadius={'5'} onClick={onToggleNav} >
              <Image className={'icon image-hover'} maxH={'5vh'} maxW={'5vh'} src='images/fast-forward-button.png' alt='Open' />
            </Box>
            <Button className={'Nav-links'} pt={'3'} variant={'link'} onClick={() => onHomeClick()} >
              <Image className={'icon'} src='images/home.png' alt='Home' />
            </Button>
            <Button className={'Nav-links'} variant={'link'} onClick={() => onCitiesClick()} >
              <Image className={'icon'} src='images/skyline.png' alt='Cities' />
            </Button>
            <Divider />
            <Button className={'Nav-links'} variant={'link'} onClick={() => onFilterClick()} >
              <Image className={'icon'} src='images/filter.png' alt='Filter Icon' />
            </Button>
          </VStack>
      </div>
  </>)
}

const DataDrawer = function(props) {
  const {state, cities, filters, handleCityListCards, resetFilter, relevance, handleRelevanceChange} = props;
  const {open, hidden, onStart, onToggleData, partialOpen} = useEitherDrawer();
  const params = useParams();

  const { width, height } = useViewport();
  const [ closedHeight, setClosedHeight ] = useState();
  const [ viewWidth, setViewWidth ] = useState(`${width * .96}px`);
  useEffect(() => {
    setClosedHeight(height)
    setViewWidth(`${width * .96}px`)
  }, [width, height]);

  const [ selected, setSelected ] = useState({alpha: "-selected", pop: "", medSal: "", bedRentOne: "", franc: ""});
  const handleSelected = (key) => {
    setSelected({alpha: "", pop: "", medSal: "", bedRentOne: "", franc: "", [key]: "-selected"})
  }

  const { user } = useContext(UserContext);
  const disableSort = ((user && user.premium) ? "" : "disable");
  const dataType = (params.city ? "city-info" : "city-list" );
  return(
  <>
    <motion.div
      id={`data-drawer`}
        className={`DataDrawer-open ${dataType}`}
        hidden={hidden.data}
        initial={false}
        onAnimationStart={() => onStart()}
        animate={{ height: (open.data ? '94vh' : (partialOpen ? '25vh' : 0)) }}
        transition={{ type: 'ease'}}
        style={{
          hidden: hidden.data,
          width: viewWidth
        }}
      >
        <Box w={viewWidth}  h={'94vh'}>
          <Flex w={viewWidth} h={'48px'}>
            {!params.city &&
            <Tooltip hasArrow className={"relevance-tip"} label='Use these to sort' closeDelay={500}>
            <HStack className={'relevance-container'}>
            { relevance === "ALPHA" ?
            <Flex className={'relevance-filter'} variant={'unstyled'} onClick={() => handleRelevanceChange("REV_ALPHA")} >
              <div className={"filter-text"}>
              Alphabetical
              </div>
              <Image className={'icon-selected'} maxH={'5vh'} maxW={'5vh'} src='images/down-arrow_2.png' alt='Sort' />
            </Flex>
            :
            <Flex
            minW={'128.11px'}
            className={'relevance-filter'}
            variant={'unstyled'}
            onClick={(e) => {
              handleRelevanceChange("ALPHA");
              handleSelected("alpha");
            }} >
            <div className={"filter-text"}>
              Alphabetical
            </div>
              <Image className={`icon${selected.alpha}`} maxH={'5vh'} maxW={'5vh'} src='images/up-arrow-arrow.png' alt='Sort' />
            </Flex>
            }{ relevance === "CITY_POP" ?
            <Flex className={'relevance-filter'} variant={'unstyled'} onClick={() => handleRelevanceChange("REV_CITY_POP")} >
            <div className={"filter-text"}>
              Population
            </div>
              <Image className={'icon-selected'} maxH={'5vh'} maxW={'5vh'} src='images/down-arrow_2.png' alt='Sort' />
            </Flex>
            :
            <Flex
            minW={'114.72px'}
            className={'relevance-filter'}
            variant={'unstyled'}
            onClick={() => {
              handleRelevanceChange("CITY_POP");
              handleSelected("pop");
            }} >
            <div className={"filter-text"}>
              Population
            </div>
              <Image className={`icon${selected.pop}`} maxH={'5vh'} maxW={'5vh'} src='images/up-arrow-arrow.png' alt='Sort' />
            </Flex>
            }{ relevance === "MED_SAL" ?
            <Flex className={'relevance-filter'} variant={'unstyled'} onClick={() => handleRelevanceChange("REV_MED_SAL")} >
            <div className={"filter-text"}>
              Median Salary
            </div>
              <Image className={'icon-selected'} maxH={'5vh'} maxW={'5vh'} src='images/down-arrow_2.png' alt='Sort' />
            </Flex>
            :
            <Flex
            minW={'142.34px'}
            className={`relevance-filter image-hover ${disableSort}`}
            variant={'unstyled'}
            onClick={(e) => {
              if (!disableSort) {
                handleRelevanceChange("MED_SAL");
                handleSelected("medSal");
              }
            }} >
            <div className={"filter-text"}>
              Median Salary
            </div>
              <Image className={`icon${selected.medSal}`} maxH={'5vh'} maxW={'5vh'} src='images/up-arrow-arrow.png' alt='Sort' />
            </Flex>
            }{ relevance === "AVG_RENT_1" ?
            <Flex className={'relevance-filter'} variant={'unstyled'} onClick={() => handleRelevanceChange("REV_AVG_RENT_1")} >
            <div className={"filter-text"}>
              Single Bedroom Rent Price
            </div>
              <Image className={'icon-selected'} maxH={'5vh'} maxW={'5vh'} src='images/down-arrow_2.png' alt='Sort' />
            </Flex>
            :
            <Flex
            minW={'237.53px'}
            className={`relevance-filter image-hover ${disableSort}`}
            variant={'unstyled'}
            onClick={() => {
              if (!disableSort) {
                handleRelevanceChange("AVG_RENT_1");
                handleSelected("bedRentOne");
              }
            }} >
            <div className={"filter-text"}>
              Single Bedroom Rent Price
            </div>
              <Image className={`icon${selected.bedRentOne}`} maxH={'5vh'} maxW={'5vh'} src='images/up-arrow-arrow.png' alt='Sort' />
            </Flex>
            }{ relevance === "FRANC" ?
            <Flex className={'relevance-filter'} variant={'unstyled'} onClick={() => handleRelevanceChange("REV_FRANC")} >
            <div className={"filter-text"}>
              Francophone Percentage
            </div>
              <Image className={'icon-selected'} maxH={'5vh'} maxW={'5vh'} src='images/down-arrow_2.png' alt='Sort' />
            </Flex>
            :
            <Flex
            minW={'226.69px'}
            className={`relevance-filter image-hover ${disableSort}`}
            variant={'unstyled'}
            onClick={() => {
              if (!disableSort) {
                handleRelevanceChange("FRANC");
                handleSelected("franc");
              }
            }} >
            <div className={"filter-text"}>
              Francophone Percentage
            </div>
              <Image className={`icon${selected.franc}`} maxH={'5vh'} maxW={'5vh'} src='images/up-arrow-arrow.png' alt='Sort' />
            </Flex>
            }
            </HStack>
            </Tooltip>
            }
            <Spacer />
            <Box className={"reset image-hover"} borderRadius={'5'} onClick={resetFilter}>
              Reset Filters
            </Box>
            <Box borderRadius={'5'} id={'data-toggle'}size={'lg'} onClick={() => onToggleData()} >
              {partialOpen ?
              <Image className={'icon image-hover'} src='images/file-upload.png' alt='Open' />
              :
              <Image className={'icon image-hover'} src='images/down-arrow.png' alt='Close' />
              }
            </Box>
          </Flex>
          {params.city ? 
          <SimpleBarReact style={{maxHeight: '90vh'}} >
            <CityDashboard state={state} />
          </SimpleBarReact>
          :
          <SimpleBarReact style={{maxHeight: '90vh'}} >
            <CityList relevance={relevance} cities={cities} filters={filters} state={state} handleCityListCards={handleCityListCards}/>
          </SimpleBarReact>
          }
        </Box>
      </motion.div>
      <div
        className={"DataDrawer-closed"}
        style={{
          position: 'fixed',
          float: 'bottom',
          width: viewWidth,
          right: '0',
          bottom: '0',
          zIndex: 400
        }}
        >
        <Flex w={viewWidth} >
          <Spacer />
          <Box className={"reset image-hover"} borderRadius={'5'} onClick={resetFilter}>
            Reset Filters
          </Box>
          <Box borderRadius={'5'} id={'data-toggle'}size={'lg'} onClick={() => onToggleData()} >
            <Image className={'icon image-hover'} src='images/file-upload.png' alt='Open' />
          </Box>
        </Flex>
      </div>
  </>)
}

export {NavDrawer, DataDrawer, useDrawerContext};