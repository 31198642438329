//@ts-check

import { JSONLD } from 'react-structured-data';
import Generic from 'react-structured-data/dist/schemas/Generic';


export function CitySchema(props) {
  const { name, longitude, latitude, province } = props;
  return (
    <JSONLD dangerouslyExposeHtml={true}>
      <Generic
      type='City'
      jsonldtype='City'
      schema={{
        name,
        longitude,
        latitude
      }}>
      </Generic>
    </JSONLD>
  )
}


// export function CitySchema(props) {
//   const { name, longitude, latitude, province } = props;
//   return (
//     <script type="application/ld+json" >
//       {
//       "@context": "https://schema.org",
//       "@type": "City",
//       "name": name,
//       "province": {
//         "@type": "state",
//         name: province
//       }, 
//       }
//     </script>
//   );
// }