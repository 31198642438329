import { Box, Text, VStack, Heading, Button, Spacer, Flex, Image, Badge } from "@chakra-ui/react";

// just a lot of similar looking code to sort the city cards by relevance
import sortCities from './sortCities';

const provinceShorthand = (province) => {
  if (province === 'Alberta') { return 'AB'; };
  if (province === 'British Columbia') { return 'BC'; };
  if (province === 'Manitoba') { return 'MB'; };
  if (province === 'New Brunswick') { return 'NB'; };
  if (province === 'Newfoundland and Labrador') { return 'NL'; };
  if (province === 'Northwest Territories') {return 'NWT'; };
  if (province === 'Nova Scotia') { return 'NS'; };
  if (province === 'Nunavut') { return 'NU'; };
  if (province === 'Ontario') { return 'ON'; };
  if (province === 'Prince Edward Island') { return 'PEI'; };
  if (province === 'Quebec') { return 'QC'; };
  if (province === 'Saskatchewan') { return 'SK'; };
  if (province === 'Yukon') { return 'YT'; };
  return 'N/A';
};

const makeCityRelevanceCards = function(state, handleCardClick, relevance) {
  const { economy, housing, demographics } = state;
  const cities = [...state.cities];
  if (cities) {
    for (const city of cities) {
      const cityEconomy = economy.filter(i => i.name === city.name)[0];
      const cityHousing = housing.filter(i => i.name === city.name)[0];
      const cityDemographics = demographics.filter(i => i.name === city.name)[0];
      city.med_sal = cityEconomy.med_household_income;
      city.avg_rent_1 = cityHousing.avg_rent_bed_1;
      city.franc = cityDemographics.population_percent_francophone;
    }
  }
  const sortedCities = sortCities(cities, relevance, state)
  const cards = sortedCities.map(city => {
    const filterActive = state.filters[city.name].active;
    const province = provinceShorthand(city.province);
    const francophone = (city.franc ? city.franc : "Premium Info")
    const filterInfo = state.filters[city.name].info.map(info => {
      return (<Text whiteSpace={'normal'} className={"CityList-desc"} fontSize='sm' noOfLines={3}>{info}</Text>)
    })
    const padTopCityName = (city.name.length > 20 ?
      '.1em'
      : (city.name.length > 14 ?
        '.7em'
        : 
           '1.2em'));
    return (<>
    {(filterActive === 0 || city.sponsored) &&
    <>
      {(city.sponsored) && <Badge variant={"outline"}>Sponsored</Badge>}
      <Box className={"CityList-item"} >
        <VStack w={'100%'} h={'85%'}>
          <Box className={"CityList-top"} w={'100%'} minH={'50%'}>
            <Flex paddingTop={'1em'}>
              <Box className={"CityList-head __box"}>
                <Heading className={"CityList-head __city"} paddingTop={padTopCityName}>{city.name}</Heading>
              </Box>
              <Heading className={"CityList-head__prov"}>
                {province}
              </Heading>
            </Flex>
          </Box>
            <Flex className={"desc-container"}>
              {filterInfo}
              Francophone Percentage: {francophone}
            </Flex>
        </VStack>
        <Flex className={"CityList-bottom"}>
          <Spacer />
          <Flex className={"citycards image-hover"} variant={'unstyled'} onClick={() => handleCardClick(city.name)} fontSize='xs'>
            <Image className={"icon"} src={'/images/apps-free-icon-font.png'} alt='Open' />
          </Flex>
        </Flex>
    </Box >
    </>}
    </>)
  })
  return (<>{cards}</>)
}

export {makeCityRelevanceCards}