//@ts-check
import {
  Button,
  Modal, ModalContent, ModalOverlay, ModalBody, ModalHeader, ModalFooter, useDisclosure,
  FormControl, FormLabel, Input, InputGroup, InputRightElement,
  Box, Spinner, Alert, Text, HStack
} from '@chakra-ui/react'
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { UserContext } from '../../Auth/UserContext';
import { Auth } from "aws-amplify";
import ResetPassword from './ResetPassword';
import { usePremiumCheckout } from '../../hooks/usePremiumCheckout';
import '../../styles/AccountModal.scss'

const ModalForm = function(props) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { accountProps } = props;
  const [ values, setValues ] = useState({formOne: '', formTwo: '', formThree: '', formFour: ''});
  const [ alert, setAlert ] = useState('');
  const [ pending, setPending ] = useState(false);
  const [ premiumChange, setPremiumChange ] = useState('');
  const [ validate, setValidate ] = useState(false);
  const [ validationCode, setValidationCode ] = useState('');
  const [ visibility, setVisibility ] = useState({
    formTwo: (accountProps.buttonOne === "Login" ? false : true),
    formThree: false,
    formFour: false
  });
  const [ resetPassword, setResestPassword ] = useState(false);

  const validateCode = async(code, values, handleSubmit, premiumChange) => {
    const email = values.formOne;
    try {
      await Auth.confirmSignUp(email, code);
      onSubmit({formOne: email, formTwo: values.formThree}, handleSubmit, "Login", premiumChange)
    } catch (error) {
        console.log('error confirming sign up', error);
    }
  }

  const onSubmit = function(values, handleSubmit, type, premiumChange) {
    setPending(true);
    handleSubmit(values, type, premiumChange, setAlert, setPending, setValidate)
    if (type !== "Create") {
      setValues({formOne: '', formTwo: '', formThree: '', formFour: ''})
    }
  };

  const handleChange = function(event, form) {
    const value = event.target.value;
    setValues(prev => {
      return {...prev, [form]: value}
    })
  }
  return (
    <>
    <Box onClick={onOpen} >
      {accountProps.bttnMsg}
    </Box>
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mt={10} >
      {resetPassword ? <ResetPassword setForm={setResestPassword} /> :
      <>
        <ModalHeader>{accountProps.header}</ModalHeader>
        <ModalBody >
          <FormControl p={'3'} isDisabled={accountProps.disable}>
              <Input value={values.formOne} onChange={event => {handleChange(event, 'formOne')}} placeholder={accountProps.formOnePlaceholder} />
          </FormControl>
          <FormControl p={'3'} isDisabled={accountProps.disable}>
            { (accountProps.buttonOne !== "Login") ? (
            <Input value={values.formTwo} onChange={event => {handleChange(event, 'formTwo')}} placeholder={accountProps.formTwoPlaceholder} />
            ) : (
              <InputGroup >
                <Input type={(visibility.formTwo ? 'text' : 'password')} value={values.formTwo} onChange={event => {handleChange(event, 'formTwo')}} placeholder={accountProps.formTwoPlaceholder} />
                <InputRightElement w={'14'} >
                  <Button
                    variant={'outline'}
                    borderRightRadius={"6px"}
                    borderLeftRadius={"0px"}
                    onClick={(e) => {setVisibility(prev => {
                    return {...prev, formTwo: !visibility.formTwo};
                  })}}>
                    {(visibility.formTwo ? 'Hide' : 'Show')}
                  </Button>
                </InputRightElement>
              </InputGroup>
            )}
          </FormControl>
          
          {accountProps.formThreePlaceholder ?
            <FormControl p={'3'} >
              <InputGroup >
                <Input type={(visibility.formThree ? 'text' : 'password')}  value={values.formThree} onChange={event => {handleChange(event, 'formThree')}} placeholder={accountProps.formThreePlaceholder} />
                <InputRightElement w={'14'} >
                  <Button
                    variant={'outline'}
                    borderRightRadius={"6px"}
                    borderLeftRadius={"0px"}
                    onClick={(e) => {setVisibility(prev => {
                    return {...prev, formTwo: !visibility.formThree};
                  })}}>
                    {(visibility.formThree ? 'Hide' : 'Show')}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            : <></>}
          {accountProps.formFourPlaceholder ?
            <FormControl p={'3'}>
              <InputGroup>
                <Input type={(visibility.formFour ? 'text' : 'password')} value={values.formFour} onChange={event => {handleChange(event, 'formFour')}} placeholder={accountProps.formFourPlaceholder} />
                <InputRightElement w={'14'} >
                  <Button
                    variant={'outline'}
                    borderRightRadius={"6px"}
                    borderLeftRadius={"0px"}
                    onClick={(e) => {setVisibility(prev => {
                      return {...prev, formTwo: !visibility.formFour};
                  })}}>
                    {(visibility.formFour ? 'Hide' : 'Show')}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            : <></>}
            {validate && <FormControl p={'3'}>
              <Input value={validationCode} onChange={e => setValidationCode(e.target.value)} placeholder={"Please enter your validation code"} />
              </FormControl>
            }

            {/* accountProps.buttonOne === "Login" */}
            {!accountProps.formThreePlaceholder && <Text onClick={() => setResestPassword(true)}>Forgot Password</Text>}
        </ModalBody>
        <ModalFooter>
          <HStack w={'20vw'} spacing={'4'}>
            <Box w={'80%'}>
              {alert &&
              <Alert status={alert.alert}> {alert.msg} </Alert>}
            </Box>
            <Box w='20%' h={'100%'} pr={'3'} pl={'3'} >
              {pending &&
              <Spinner />}
            </Box>
          </HStack>
          { validate ? (
          <Button
            mr={'2'}
            padding={'2'}
            variant={'outline'}
            borderRadius={'5'}
            onClick={() => {
              validateCode(validationCode, values, accountProps.handleSubmit, accountProps.buttonOne, premiumChange)
            }} >
              Validate
          </Button>
          ):(
          <Button
            mr={'2'}
            padding={'2'}
            variant={'outline'}
            borderRadius={'5'}
            disabled={pending}
            onClick={() => {
              onSubmit(values, accountProps.handleSubmit, accountProps.buttonOne, premiumChange)
            }} >{accountProps.buttonOne}
          </Button>
          )}
          <Button
            mr={'3'}
            padding={'2'}
            variant={'outline'}
            borderRadius={'5'}
            onClick={() => {
              accountProps.handleCancel();
              navigate("/");
              onClose();
            }} >Cancel</Button>
        </ModalFooter>
      </>}
      </ModalContent>
    </Modal>
    </>
  )
}

export { ModalForm }


// an option for adding an add / remove premium button to the bottom of the form
// add after line 153

// {(user && user.premium) &&
//   <Button borderRadius={'5'} variant={'outline'} float={'right'} mr={'3'} p={'3'} backgroundColor={premColor} onClick={() => purchasePrem(false)}>Remove Premium</Button>}
//   {(accountProps.buttonOne === "Create") &&
//   <Button borderRadius={'5'} variant={'outline'} float={'right'} mr={'3'} p={'3'} backgroundColor={premColor} onClick={() => purchasePrem(true)}>Purchase Premium</Button>}
//   {premColor === "green" && <Text fontSize={'xs'}>Temporary Premium will Activate</Text>}
//   {premColor === "pink" && <Text fontSize={'xs'}>Temporary Premium will Deactivate</Text>}