import { FormControl, Button, Input, VStack, Box, Alert, Spinner, Text } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../Auth/UserContext';
import { Auth } from "aws-amplify";

const FormPage = function(props) {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  
  const accountProps = props.accountProps();
  const [ values, setValues ] = useState({formOne: null, formTwo: null, formThree: null, formFour: null})
  const [ alert, setAlert ] = useState('');
  const [ pending, setPending ] = useState(false);
  const [ premiumChange, setPremiumChange ] = useState('');
  const [ premColor, setPremColor ] = useState('blue')
  const [ validate, setvalidate ] = useState(false);
  const [ validationCode, setValidationCode ] = useState('');


  const onSubmit = function(values, handleSubmit, type, premiumChange) {
    setPending(true);
    handleSubmit(values, type, premiumChange, setAlert, setPending, setvalidate)
    if (type !== "Create") {
      setValues({formOne: '', formTwo: '', formThree: '', formFour: ''})
    }
  };

  
  const validateCode = async(code, values, handleSubmit, premiumChange) => {
    const email = values.formOne;
    try {
      await Auth.confirmSignUp(email, code);
      onSubmit({formOne: email, formTwo: values.formThree}, handleSubmit, "Login", premiumChange)
    } catch (error) {
        console.log('error confirming sign up', error);
    }
  }

  const handleChange = function(event, form) {
    const value = event.target.value;
    setValues(prev => {
      return {...prev, [form]: value}
    })
  }

  const purchasePrem = function(change) {
    console.log(premColor)
    if (premiumChange !== '') {
      setPremiumChange('');
      setPremColor('blue');
      return;
    }
    if (user.username ) {
      if (change) {
        setPremiumChange(change)
        setPremColor('green');
        return
      }
      setPremiumChange(change)
      setPremColor('pink');
      return
    }
    setPremiumChange(true);
    setPremColor('green');
    return;
  }

  return (
  <>
  <Box>
    <Box w={'em'}>
      {alert &&
      <Alert status={alert.alert}> {alert.msg} </Alert>}
    </Box>
  </Box>
  {pending && <Spinner />}
  <VStack mt={40} mb={20} spacing={10}>
    <Box fontSize={30}>
      {accountProps.header}
    </Box>
    <VStack spacing={8}>
      <FormControl isDisabled={accountProps.disable}>
        <Input value={values.formOne} onChange={event => {handleChange(event, 'formOne')}} placeholder={accountProps.formOnePlaceholder} />
      </FormControl>
      <FormControl isDisabled={accountProps.disable}>
        <Input value={values.formTwo} onChange={event => {handleChange(event, 'formTwo')}} placeholder={accountProps.formTwoPlaceholder} />
      </FormControl>
      {accountProps.formThreePlaceholder ?
        <FormControl >
          <Input value={values.formThree} onChange={event => {handleChange(event, 'formThree')}} placeholder={accountProps.formThreePlaceholder} />
        </FormControl>
        : <></>}
      {accountProps.formFourPlaceholder ?
        <FormControl>
          <Input value={values.formFour} onChange={event => {handleChange(event, 'formFour')}} placeholder={accountProps.formFourPlaceholder} />
        </FormControl>
        : <></>}
      {(user && user.premium) &&
        <Button backgroundColor={premColor} onClick={() => purchasePrem(false)}>Remove Premium</Button>}
        {(accountProps.buttonOne === "Create" || accountProps.buttonOne === "Save") &&
        <Button backgroundColor={premColor} onClick={() => purchasePrem(true)}>Purchase Premium</Button>}
        {premColor === "green" && <Text fontSize={'xs'}>Temporary Premium will Activate</Text>}
        {premColor === "pink" && <Text fontSize={'xs'}>Temporary Premium will Deactivate</Text>}
        {validate && <Input value={validationCode} onChange={e => setValidationCode(e.target.value)} placeholder={"Please enter your validation code from your email"} />}
          
    </VStack>
    <Box>
    { validate ? (
      <Button
        onClick={() => {
          validateCode(validationCode, values, accountProps.handleSubmit, accountProps.buttonOne, premiumChange)
        }} >
          Validate
      </Button>
      ):(
      <Button disabled={pending}
        onClick={() => {
          onSubmit(values, accountProps.handleSubmit, accountProps.buttonOne, premiumChange)
        }} >{accountProps.buttonOne}
      </Button>
    )}
      <Button
        onClick={() => {
          accountProps.handleCancel();
          navigate("/");
        }} >Cancel</Button>
    </Box>
  </VStack>
  </>
  )
}

export { FormPage }