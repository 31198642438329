//@ts-check
import { InteractiveMap, InteractiveMapSmall } from "./InteractiveMap";
import Legend from "./Legend";

import "../../styles/Home.scss";
import CityList from "./CityList";
import { useState } from "react";
import { NavDrawer, DataDrawer } from "../Drawer/Drawers";

const Home = function(props) {
  const {state, mapMarkers, cities, handleCheckboxes, handleSliderEvent, filters, handleCityListCards, resetFilter, resetHeuristicKey} = props;
  const [ relevance, setRelevance ] = useState("ALPHA");
  const handleRelevanceChange = function(mode) {
    setRelevance(mode);
  }

  return (
  <div className="Home-container">
    <div className="Home-top">
      <NavDrawer resetHeuristicKey={resetHeuristicKey} resetFilter={resetFilter} handleCheckboxes={handleCheckboxes} handleSliderEvent={handleSliderEvent} />
      <InteractiveMap filters={filters} mapMarkers={mapMarkers} cities={cities} />
      <InteractiveMapSmall filters={filters} mapMarkers={mapMarkers} cities={cities} />
    </div>
    <div className="Home-bottom">
      <Legend />
      <DataDrawer relevance={relevance} handleRelevanceChange={handleRelevanceChange} resetFilter={resetFilter} state={state} filters={filters} cities={cities} handleCityListCards={handleCityListCards}/>
    </div>
  </div>);
}

export default Home;