import { Input, Box, InputGroup, InputRightElement, IconButton, Button, VStack, Center } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from 'react-router';
import { motion } from 'framer-motion';
import { SearchIcon } from "@chakra-ui/icons";
import SimpleBarReact from "simplebar-react";

import "simplebar/src/simplebar.css";

const SearchBar = function(props) {
  const { cities } = props;
  const [ value, setValue ] = useState('');
  const [ select, setSelect ] = useState(false)
  const [ hidden, setHidden ] = useState(true);
  const [ open, setOpen ] = useState(false);

  const navigate = useNavigate();

  const boxSize = {minHeight: open ? '5em' : 0, maxHeight: open ? '10em': 0}
  const lowercaseValue = value.toLowerCase()

  const onChange = (e) => {
    setValue(e.target.value)
  }

  const onSubmit = (e) => {
    if (e.key !== "Enter" && e.type !== 'click') {
      return;
    }
    const city = value.split(',')[0];
    navigate(`/${city}`);
  }
  const onFocus = (e) => {
    setHidden(false);
    setOpen(true);
  };
  const onBlur = (e) => {
    setTimeout(() => {
      setHidden(true);
      setOpen(false);
      setSelect(false);
    }, 150);
  }
  const onSelect = (e) => {
    setSelect(true);
    setValue(e.target.innerText);
    document.getElementById("search-bar").focus();
  };

  const alphabeticalCities = cities.sort((a,b) => {
    const provinceA = a.province.toLowerCase();
    const provinceB = b.province.toLowerCase();
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (lowercaseValue[0] === nameA[0] && lowercaseValue[0] !== nameB[0]) {
      return -1;
    }
    if (lowercaseValue[0] === nameB[0] && lowercaseValue[0] !== nameA[0]) {
      return 1;
    }
    if (provinceA > provinceB) {
      return 1;
    }
    if (provinceA < provinceB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    if (nameA < nameB) {
      return -1;
    }
    return 0;
  });

  let count = 0;
  const cityList = alphabeticalCities.map((city) => {
    count++;
    const lowercaseCity = city.name.toLowerCase();
    const lowercaseProvince = city.province.toLowerCase();
    if (lowercaseCity.includes(lowercaseValue) || lowercaseProvince.includes(lowercaseValue) || value === '') {
      return (
        <Center cursor={'pointer'} w={'14em'} fontSize={'lg'} variant={'unstyled'} key={count} onClick={onSelect} >{city.name}, {city.province}</Center>
      )
    }
    return;
  })
  const focus = (open ? "selected" : "");

  return (
    <Box
      onFocus={onFocus}
      onBlur={onBlur}>
      <InputGroup variant={"ghost"} className={`search-bar-group ${focus}`}>
        <Input className={"search-field"} placeholder={"Search Cities"} id={"search-bar"} value={value} onChange={onChange} onKeyUp={onSubmit} />
        <InputRightElement className={"search-field"} children={<IconButton onClick={onSubmit} className={"search-icon"} borderLeftRadius={'0'} variant={'unstyled'} icon={<img style={{maxHeight: '30px'}} src='images/loupe.png'/>} />} />
      </InputGroup>
      <motion.div
      className={"search-results search-field"}
      hidden={hidden}
      animate={boxSize}
      initial={false}
      >
        <Box id={"search-field"} onBlur={onBlur} className={"search-field"} >
          <SimpleBarReact style={{ maxHeight: '5em'}} >
            <VStack className={"search-field"} >
              {cityList}
            </VStack>
          </SimpleBarReact>
        </Box>
      </motion.div>
    </Box>
  )
}

export default SearchBar;