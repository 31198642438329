//@ts-check

import { NavLink } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuList, Button, Image, Link } from '@chakra-ui/react';
import logout from './logout';
import { SettingsDesktop } from './AccountDesktop';
import useViewport from '../../hooks/useViewport';
import { useContext } from 'react';
import { UserContext } from '../../Auth/UserContext';


const AccountMenu = function() {
  const { user, setUser } = useContext(UserContext)
  const { width, height } = useViewport();
  const breakpoint = 0;
  if (!user) {
    return (<></>);
  }
  return (
  <Menu>
    <MenuButton className={'image-hover'}  >
        <Image className={'menu-image'} src='images/menu.png' alt='Menu' />
    </MenuButton>
    <MenuList>
      {
      width > breakpoint ?
      <MenuItem><SettingsDesktop /></MenuItem>
      : <MenuItem as={NavLink} to={'/account-settings'} >Account Settings</MenuItem>
      }
      <MenuItem as={Link} href={"https://www.google.com"} >FAQ</MenuItem>
      <MenuItem as={NavLink} to={'/'} onClick={() => {
        logout();
        setUser(null);
        }}>Logout</MenuItem>
    </MenuList>
  </Menu>)
}

export default AccountMenu;