const { useState } = require("react")


const useMapHover = function() {
  const [hoverPosition, setHoverPosition] = useState([-97.0025, 60.0219])
  const handleHover = function(event) {
    setHoverPosition([event.lngLat[0].toFixed(4), event.lngLat[1].toFixed(4)]);
  }
  return {hoverPosition, handleHover};
}

export {useMapHover}