import { loadStripe } from '@stripe/stripe-js';

const usePremiumCheckout = function() {

  const handleCancelPremium = async(id) => {

  }

  const handleCheckout = async(id, price) => {
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;
    const publish = (hostname === "moveincanada.ca" ? process.env.REACT_APP_TEST_STRIPE_PUBLISH : process.env.REACT_APP_TEST_STRIPE_PUBLISH);
    const product = (hostname === "moveincanada.ca" ? process.env.REACT_APP_STRIPE_PRODUCT : process.env.REACT_APP_TEST_STRIPE_PRODUCT);
    const stripe = await loadStripe(publish)
    console.log(price)
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{
        price,
        quantity: 1
      }],
      mode: 'subscription',
      clientReferenceId: id,
      successUrl: (hostname === 'localhost' ? `http://localhost:3000/checkout/purchase` : `${protocol}//${hostname}/checkout/purchase`),
      cancelUrl: (hostname === 'localhost' ? `http://localhost:3000/` : `${protocol}//${hostname}/`)
    })
  }
  return {handleCheckout};
};

export { usePremiumCheckout };