
// take in a relevance mode from the wherever (currently only in the dataDrawer) and sort by that mode
// 10 current modes:
// DEFAULT, ALPHA, REV_ALPHA, CITY_POP, REV_CITY_POP, MED_SAL, REV_MED_SAL, AVG_RENT_1, REV_ABG_RENT_1, FRANC, REV_FRANC

const sortByMode = function(cities, mode) {
  if (!cities) {
    return;
  }

  switch (mode) {

    // DEFAULT would order alphabetically, but by province first
    case 'DEFAULT':
      return cities.sort((a,b) => {
        const provinceA = a.province;
        const provinceB = b.province;
        if (provinceA > provinceB) {
          return 1;
        }
        if (provinceA < provinceB) {
          return -1;
        }
        const nameA = a.name;
        const nameB = b.name;
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      });
      break;
    case 'REV_DEFAULT':
      return cities.sort((a,b) => {
        const provinceA = a.province;
        const provinceB = b.province;
        if (provinceA < provinceB) {
          return 1;
        }
        if (provinceA > provinceB) {
          return -1;
        }
        const nameA = a.name;
        const nameB = b.name;
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
    });
    case 'ALPHA':
      return cities.sort((a,b) => {
        const nameA = a.name;
        const nameB = b.name;
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        const provinceA = a.province;
        const provinceB = b.province;
        if (provinceA > provinceB) {
          return 1;
        }
        if (provinceA < provinceB) {
          return -1;
        }
        return 0;
    });
    case 'REV_ALPHA':
      return cities.sort((a,b) => {
        const nameA = a.name;
        const nameB = b.name;
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        const provinceA = a.province;
        const provinceB = b.province;
        if (provinceA < provinceB) {
          return 1;
        }
        if (provinceA > provinceB) {
          return -1;
        }
        return 0;
    });
    case 'CITY_POP':
      console.log("Pop");
      return cities.sort((a,b) => {
        const populationA = a.population_city;
        const populationB = b.population_city;
        if (populationA > populationB) {
          return 1;
        }
        if (populationA < populationB) {
          return -1;
        }
        const nameA = a.name;
        const nameB = b.name;
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        const provinceA = a.province;
        const provinceB = b.province;
        if (provinceA > provinceB) {
          return 1;
        }
        if (provinceA < provinceB) {
          return -1;
        }
        return 0;
    });
    case 'REV_CITY_POP':
      console.log("Rev_Pop");
      return cities.sort((a,b) => {
        const populationA = a.population_city;
        const populationB = b.population_city;
        if (populationA < populationB) {
          return 1;
        }
        if (populationA > populationB) {
          return -1;
        }
        const nameA = a.name;
        const nameB = b.name;
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        const provinceA = a.province;
        const provinceB = b.province;
        if (provinceA < provinceB) {
          return 1;
        }
        if (provinceA > provinceB) {
          return -1;
        }
        return 0;
    });
    case 'MED_SAL':
      return cities.sort((a,b) => {
        const medSalA = a.med_sal;
        const medSalB = b.med_sal;
        if (medSalA > medSalB) {
          return 1;
        }
        if (medSalA < medSalB) {
          return -1;
        }
        const nameA = a.name;
        const nameB = b.name;
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        const provinceA = a.province;
        const provinceB = b.province;
        if (provinceA > provinceB) {
          return 1;
        }
        if (provinceA < provinceB) {
          return -1;
        }
        return 0;
    });
    case 'REV_MED_SAL':
      return cities.sort((a,b) => {
        const medSalA = a.med_sal;
        const medSalB = b.med_sal;
        if (medSalA < medSalB) {
          return 1;
        }
        if (medSalA > medSalB) {
          return -1;
        }
        const nameA = a.name;
        const nameB = b.name;
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        const provinceA = a.province;
        const provinceB = b.province;
        if (provinceA < provinceB) {
          return 1;
        }
        if (provinceA > provinceB) {
          return -1;
        }
        return 0;
      });
      case 'AVG_RENT_1':
        return cities.sort((a,b) => {
          const avgRent1A = a.avg_rent_1;
          const avgRent1B = b.avg_rent_1;
          if (avgRent1A > avgRent1B) {
            return 1;
          }
          if (avgRent1A < avgRent1B) {
            return -1;
          }
          const nameA = a.name;
          const nameB = b.name;
          if (nameA > nameB) {
            return 1;
          }
          if (nameA < nameB) {
            return -1;
          }
          const provinceA = a.province;
          const provinceB = b.province;
          if (provinceA > provinceB) {
            return 1;
          }
          if (provinceA < provinceB) {
            return -1;
          }
          return 0;
      });
      case 'REV_AVG_RENT_1':
        return cities.sort((a,b) => {
          const avgRent1A = a.avg_rent_1;
          const avgRent1B = b.avg_rent_1;
          if (avgRent1A < avgRent1B) {
            return 1;
          }
          if (avgRent1A > avgRent1B) {
            return -1;
          }
          const nameA = a.name;
          const nameB = b.name;
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          const provinceA = a.province;
          const provinceB = b.province;
          if (provinceA < provinceB) {
            return 1;
          }
          if (provinceA > provinceB) {
            return -1;
          }
          return 0;
      });
      case 'FRANC':
        return cities.sort((a,b) => {
          const francA = a.franc;
          const francB = b.franc;
          if (francA > francB) {
            return 1;
          }
          if (francA < francB) {
            return -1;
          }
          const nameA = a.name;
          const nameB = b.name;
          if (nameA > nameB) {
            return 1;
          }
          if (nameA < nameB) {
            return -1;
          }
          const provinceA = a.province;
          const provinceB = b.province;
          if (provinceA > provinceB) {
            return 1;
          }
          if (provinceA < provinceB) {
            return -1;
          }
          return 0;
      });
      case 'REV_FRANC':
        return cities.sort((a,b) => {
          const francA = a.franc;
          const francB = b.franc;
          if (francA < francB) {
            return 1;
          }
          if (francA > francB) {
            return -1;
          }
          const nameA = a.name;
          const nameB = b.name;
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          const provinceA = a.province;
          const provinceB = b.province;
          if (provinceA < provinceB) {
            return 1;
          }
          if (provinceA > provinceB) {
            return -1;
          }
          return 0;
      });
  }
}

const sortCities = function(cities, mode, state) {
  const relevanceSorted = sortByMode(cities, mode)
  const sortedCities = relevanceSorted.sort((a,b) => {
    const refA = state.filters[a.name].filterRef;
    const refB = state.filters[b.name].filterRef;
    if (refA > refB) {
      return 1;
    }
    if (refA < refB) {
      return -1;
    }
    return 0;
  });
  return sortedCities.sort((a,b) => {
    const sponsoredA = a.sponsored;
    const sponsoredB = b.sponsored;
    if (sponsoredA > sponsoredB) {
      return 1;
    }
    if (sponsoredA < sponsoredB) {
      return -1;
    }
    return 0;
  });
}

export default sortCities;