
//code works for creating the form, but the abiltity to change the password or email is not available

const accountSettingsProps = function(disable, handleCancel, handleSubmit) {
  const bttnMsg = "Account Settings";
  const header = "Account Settings";
  const formOnePlaceholder = "Change Password";
  const formTwoPlaceholder = "By Pressing Reset Password";
  const buttonOne = (disable ? "Edit" : "Save");
  let formThreePlaceholder;
  let formFourPlaceholder;
  // if (!disable) {
  //   formThreePlaceholder = "Confirm Password";
  //   formFourPlaceholder = "Old Password";
  // }
  return {disable, bttnMsg, header, buttonOne, formOnePlaceholder, formTwoPlaceholder, formThreePlaceholder, formFourPlaceholder, handleCancel, handleSubmit }
}

const accountLoginProps = function(disable, handleCancel, handleSubmit) {
  const bttnMsg = "Login";
  const header = "Login";
  const formOnePlaceholder = "Email";
  const formTwoPlaceholder = "Password";
  const buttonOne = "Login";
  return {disable, bttnMsg, header, buttonOne, formOnePlaceholder, formTwoPlaceholder, handleCancel, handleSubmit }
}

const accountRegisterProps = function(disable, handleCancel, handleSubmit) {
  const bttnMsg = "Create Account"
  const header = "Create Account"
  const formOnePlaceholder = "Email"
  const formTwoPlaceholder = "Confirm Email"
  const buttonOne = "Create"
  let formThreePlaceholder = "Password"
  let formFourPlaceholder = "Confirm Password"
  return {disable, bttnMsg, header, buttonOne, formOnePlaceholder, formTwoPlaceholder, formThreePlaceholder, formFourPlaceholder, handleCancel, handleSubmit }
}





export { accountSettingsProps, accountLoginProps, accountRegisterProps }