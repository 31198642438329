/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCity = /* GraphQL */ `
  query GetCity($id: ID!) {
    getCity(id: $id) {
      id
      name
      nickname
      province
      population_city
      population_urban
      population_year
      population_density
      sponsored
      med_age
      summary
      longitude
      latitude
      population_percent_immigrant
      population_percent_francophone
      number_french_school_program
      language_first
      language_second
      number_pool
      number_parks
      number_museum
      number_movie_theatre
      number_bar_restaurant
      number_festivals
      avg_winter_temp
      avg_summer_temp
      activities
      minmium_wage
      avg_household_income
      med_household_income
      unemployment_rate
      unemployment_year
      economy_sector
      active_health_system
      number_hospitals
      number_children_hospitals
      number_beds
      number_sick_paid
      number_sick_unpaid
      clinic_population_density
      library_density
      groceries_density
      avg_crime_rate
      avg_house_price
      avg_rent_studio
      avg_rent_bed_1
      avg_rent_bed_2
      congestion_level
      avg_daily_transport_time
      bus_lines
      rail_lines
      km_rail
      domestic_connections
      international_connections
      france_flights
      createdAt
      updatedAt
    }
  }
`;
export const listCities = /* GraphQL */ `
  query ListCities(
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        nickname
        province
        population_city
        population_urban
        population_year
        population_density
        sponsored
        med_age
        summary
        longitude
        latitude
        population_percent_immigrant
        population_percent_francophone
        number_french_school_program
        language_first
        language_second
        number_pool
        number_parks
        number_museum
        number_movie_theatre
        number_bar_restaurant
        number_festivals
        avg_winter_temp
        avg_summer_temp
        activities
        minmium_wage
        avg_household_income
        med_household_income
        unemployment_rate
        unemployment_year
        economy_sector
        active_health_system
        number_hospitals
        number_children_hospitals
        number_beds
        number_sick_paid
        number_sick_unpaid
        clinic_population_density
        library_density
        groceries_density
        avg_crime_rate
        avg_house_price
        avg_rent_studio
        avg_rent_bed_1
        avg_rent_bed_2
        congestion_level
        avg_daily_transport_time
        bus_lines
        rail_lines
        km_rail
        domestic_connections
        international_connections
        france_flights
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPremium = /* GraphQL */ `
  query GetPremium($id: ID!) {
    getPremium(id: $id) {
      id
      premium
      username
      email
      createdAt
      updatedAt
    }
  }
`;
export const listPremiums = /* GraphQL */ `
  query ListPremiums(
    $filter: ModelPremiumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPremiums(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        premium
        username
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
