import {
  VStack, Text, Heading,
  MenuButton, MenuList,
  Checkbox, CheckboxGroup,
  Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton,
  RangeSlider, RangeSliderThumb, RangeSliderTrack, RangeSliderFilledTrack, Button
 } from '@chakra-ui/react';
import { useContext } from 'react';
import { UserContext } from '../../../Auth/UserContext';
import SimpleBarReact from "simplebar-react";

import "simplebar/src/simplebar.css";


const WeatherFilters = function(props) {
  const { user } = useContext(UserContext);
  const { sliderValues, handleSliderEvent, handleSlider, sizes } = props;
  const premium = (user ? (user.premium ? true : false) : false);
  const freemium = (user ? true : false);
  const { heading, title, text, value } = sizes;
  return (
    <>
      <AccordionItem w={'100%'}>
        <AccordionButton size={heading}>
          <Heading className={'filter-heading'} flex='1' textAlign='left' size={heading}>Weather</Heading>
          <AccordionIcon />
        </AccordionButton>
        <SimpleBarReact style={{maxHeight: '30vh'}} >
          <AccordionPanel>
            <VStack mb={2}>
            <Text as='u' fontSize={title}>Average Temperatures</Text>
              <Text fontSize={text} className={'filter-desc'}>Summer</Text>
              <Text fontSize={value}>{sliderValues[4][0]} - {sliderValues[4][1]}ºC</Text>
              <RangeSlider
                aria-label={['min', 'max']}
                isDisabled={!freemium}
                min={10}
                max={30}
                step={1}
                colorScheme='gray'
                defaultValue={[10, 30]}
                onChange={(event) => {
                  handleSlider(event, 4, 'social_civil_capital-avg_summer_temp-^', handleSliderEvent)
                }}
              >
                <RangeSliderTrack>
                  <RangeSliderFilledTrack />
                </RangeSliderTrack>
                <RangeSliderThumb index={0} ></RangeSliderThumb>
                <RangeSliderThumb index={1} />
              </RangeSlider>
              <Text fontSize={text} className={'filter-desc'}>Winter</Text>
              <Text fontSize={value}>{sliderValues[5][0]} - {sliderValues[5][1]}ºC</Text>
              <RangeSlider
                aria-label={['min', 'max']}
                isDisabled={!freemium}
                min={-20}
                max={10}
                step={1}
                colorScheme='gray'
                defaultValue={[-20, 10]}
                onChange={(event) => {
                  handleSlider(event, 5, 'social_civil_capital-avg_winter_temp-^', handleSliderEvent)
                }}
              >
                <RangeSliderTrack>
                  <RangeSliderFilledTrack />
                </RangeSliderTrack>
                <RangeSliderThumb index={0} ></RangeSliderThumb>
                <RangeSliderThumb index={1} />
              </RangeSlider>
              </VStack>
          </AccordionPanel>
        </SimpleBarReact>
      </AccordionItem>
    </>
  )
}

export { WeatherFilters }