import {
  VStack, SimpleGrid, Menu, Spacer, Accordion, Box, Image, Flex, Heading
 } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import useViewport from '../../../hooks/useViewport'
import { useSliderValues } from '../../../hooks/useSliderValues';
import { HousingFilters } from './HousingFilters';
import { WeatherFilters } from './WeatherFilters'
import { SocialCivilFilters } from './SocialCivilFilters';
import { HealthCareFilters } from './HealthCareFilters';
import { AmenitiesFilters } from './AmenitiesFilters';
import { InfastructureFilters } from './InfrastructureFilters';
import { EconomyFilters } from './EconomyFilters';
import { DemographicsFilters } from './DemographicsFilters';
import SimpleBarReact from "simplebar-react";

import "simplebar/src/simplebar.css";

const Filters = function(props) {
  const { handleCheckboxes, handleSliderEvent } = props
  const { sliderValues, handleSlider } = useSliderValues();
  const { width } = useViewport();
  const [ sizes, setSizes ] = useState({
    heading: (width > 0 ? 'md' : 'xs'),
    title: (width > 0 ? 'md' : 'xs'),
    text: (width > 0 ? 'sm' : 'xx-small'),
    value: (width > 0 ? 'xs' : 'xx-small'),
    width: (width > 0 ? 200 : 100)
  })
  useEffect(() => {
    setSizes(prev => {
      return {
        ...prev,
        heading: (width > 0 ? 'xs' : 'xs'),
        title: (width > 0 ? 'sm' : 'xs'),
        text: (width > 0 ? 'xs' : 'xx-small'),
        value: (width > 0 ? 'xs' : 'xx-small'),
        width: (width > 0 ? '4vw' : 100)
      }
    })
  }, [width])

  const [ openWidth, setOpenWidth ] = useState(width > 1100 ? "16vw" : "30vw");
  useEffect(() => {
    setOpenWidth(width > 1100 ? "15.5vw" : "29.5vw");
  }, [width])
  // separated by each Accordion into differnt files
  // initial state should be marked better, for now
  // use comments and reorganize them based off of
  // what order they shoul display in


  // second left for potential use in adjusting for smaller media.
  // Currently would break, if put in use, because each filter
  // is setup for accordion only
  return (
  <div className="Filters-container">
    <div className="Filters-flex">
      <div className="Filters-filters">
      {width > 0 ? (
        <Box >
          <Flex justifyContent={'space-evenly'}>
            <Image marginRight={'.6em'} maxH={'5vh'} maxW={'5vh'} src='images/filter.png' alt='Cities' />
            <Heading marginRight={'1.5em'} textAlign={'center'} h={'4vh'} lineHeight={'4vh'} className="Filters-header" size={'md'} >
                Filters
            </Heading>
          </Flex>
          <Accordion  allowToggle defaultIndex={[0]}>
            <VStack spacing={'1'} w={openWidth} align={'start'}>
            <DemographicsFilters sizes={sizes} sliderValues={sliderValues} handleSliderEvent={handleSliderEvent} handleSlider={handleSlider} handleCheckboxes={handleCheckboxes} />
            <HousingFilters sizes={sizes} sliderValues={sliderValues} handleSliderEvent={handleSliderEvent} handleSlider={handleSlider} />
            <SocialCivilFilters sizes={sizes} sliderValues={sliderValues} handleSliderEvent={handleSliderEvent} handleSlider={handleSlider} handleCheckboxes={handleCheckboxes} />
            <HealthCareFilters sizes={sizes} sliderValues={sliderValues} handleSliderEvent={handleSliderEvent} handleSlider={handleSlider} handleCheckboxes={handleCheckboxes} />
            <AmenitiesFilters />
            <InfastructureFilters sizes={sizes} sliderValues={sliderValues} handleSliderEvent={handleSliderEvent} handleSlider={handleSlider} />
            <EconomyFilters sizes={sizes} sliderValues={sliderValues} handleSliderEvent={handleSliderEvent} handleSlider={handleSlider} handleCheckboxes={handleCheckboxes} />
            <WeatherFilters sizes={sizes} sliderValues={sliderValues} handleSliderEvent={handleSliderEvent} handleSlider={handleSlider} handleCheckboxes={handleCheckboxes}  />
            </VStack>
          </Accordion>
        </Box>
        ) : (
          <SimpleGrid minChildWidth="10em" spacingX=".3em" spacingY=".5em">
            <Menu>
              <HousingFilters sizes={sizes} sliderValues={sliderValues} handleSliderEvent={handleSliderEvent} handleSlider={handleSlider} />
            </Menu>
            <Menu>
              <SocialCivilFilters sizes={sizes} sliderValues={sliderValues} handleSliderEvent={handleSliderEvent} handleSlider={handleSlider} handleCheckboxes={handleCheckboxes} />
            </Menu>
            <Menu>
              <HealthCareFilters sizes={sizes} sliderValues={sliderValues} handleSliderEvent={handleSliderEvent} handleSlider={handleSlider} handleCheckboxes={handleCheckboxes} />
            </Menu>
               <AmenitiesFilters />
            <Menu>
              <InfastructureFilters sizes={sizes} sliderValues={sliderValues} handleSliderEvent={handleSliderEvent} handleSlider={handleSlider} />
            </Menu>
            <Menu>
              <EconomyFilters sizes={sizes} sliderValues={sliderValues} handleSliderEvent={handleSliderEvent} handleSlider={handleSlider} handleCheckboxes={handleCheckboxes} />
            </Menu>
            <Menu>
              <DemographicsFilters sizes={sizes} sliderValues={sliderValues} handleSliderEvent={handleSliderEvent} handleSlider={handleSlider} handleCheckboxes={handleCheckboxes} />
            </Menu>
            <Menu>
              <WeatherFilters sizes={sizes} sliderValues={sliderValues} handleSliderEvent={handleSliderEvent} handleSlider={handleSlider} handleCheckboxes={handleCheckboxes}  />
            </Menu>
          </SimpleGrid>
        )}
      </div>
    </div>
  </div>);
}

export default Filters;