import {
  VStack, Text, Heading,
  MenuButton, MenuList,
  Checkbox, CheckboxGroup,
  Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton,
  RangeSlider, RangeSliderThumb, RangeSliderTrack, RangeSliderFilledTrack, Button
 } from '@chakra-ui/react';
import { useContext } from 'react';
import { UserContext } from '../../../Auth/UserContext';
import SimpleBarReact from "simplebar-react";

import "simplebar/src/simplebar.css";


const SocialCivilFilters = function(props) {
  const { user } = useContext(UserContext);
  const { sliderValues, handleSliderEvent, handleSlider, handleCheckboxes, sizes } = props;
  const premium = (user ? (user.premium ? true : false) : false);
  const freemium = (user ? true : false);
  const { heading, title, text, value } = sizes;
  return (
    <>
      <AccordionItem w={'100%'}>
        <AccordionButton size={heading}>
          <Heading className={'filter-heading'} flex='1' textAlign='left' size={heading}>Wellness</Heading>
          <AccordionIcon />
        </AccordionButton>
        <SimpleBarReact style={{maxHeight: '30vh'}} >
          <AccordionPanel>
            <VStack mb={2}>
              <Text as='u' pt={'2'} fontSize={title}>Destination Activites</Text>
                <Text fontSize={text} className={'filter-desc'}>Museums</Text>
                  <Text fontSize={value}>{sliderValues[6][0]} - {sliderValues[6][1]}</Text>
                  <RangeSlider
                    aria-label={['min', 'max']}
                    isDisabled={!premium}
                    min={0}
                    max={50}
                    step={5}
                    colorScheme='gray'
                    defaultValue={[0, 50]}
                    onChange={(event) => {
                      handleSlider(event, 6, 'social_civil_capital-number_museum-^', handleSliderEvent)
                    }}
                  >
                    <RangeSliderTrack>
                      <RangeSliderFilledTrack />
                    </RangeSliderTrack>
                    <RangeSliderThumb index={0} ></RangeSliderThumb>
                    <RangeSliderThumb index={1} />
                  </RangeSlider>
                  <Text fontSize={text} className={'filter-desc'}>Movie Theatres</Text>
                    <Text fontSize={value}>{sliderValues[7][0]} - {sliderValues[7][1]}</Text>
                  <RangeSlider
                    aria-label={['min', 'max']}
                    isDisabled={!premium}
                    min={0}
                    max={15}
                    step={1}
                    colorScheme='gray'
                    defaultValue={[0, 15]}
                    onChange={(event) => {
                      handleSlider(event, 7, 'social_civil_capital-number_movie_theatre-^', handleSliderEvent)
                    }}
                  >
                    <RangeSliderTrack>
                      <RangeSliderFilledTrack />
                    </RangeSliderTrack>
                    <RangeSliderThumb index={0} ></RangeSliderThumb>
                    <RangeSliderThumb index={1} />
                  </RangeSlider>
                  <Text fontSize={text} className={'filter-desc'}>Pools</Text>
                    <Text fontSize={value}>{sliderValues[8][0]} - {sliderValues[8][1]}</Text>
                  <RangeSlider
                    aria-label={['min', 'max']}
                    isDisabled={!premium}
                    min={0}
                    max={10}
                    step={1}
                    colorScheme='gray'
                    defaultValue={[0, 10]}
                    onChange={(event) => {
                      handleSlider(event, 8, 'social_civil_capital-number_pool-^', handleSliderEvent)
                    }}
                  >
                    <RangeSliderTrack>
                      <RangeSliderFilledTrack />
                    </RangeSliderTrack>
                    <RangeSliderThumb index={0} ></RangeSliderThumb>
                    <RangeSliderThumb index={1} />
                  </RangeSlider>
                  <Text fontSize={text} className={'filter-desc'}>Bars and Restaurants</Text>
                    <Text fontSize={value}>{sliderValues[10][0]} - {sliderValues[10][1]}</Text>
                  <RangeSlider
                    aria-label={['min', 'max']}
                    isDisabled={!premium}
                    min={0}
                    max={7500}
                    step={10}
                    colorScheme='gray'
                    defaultValue={[0, 7500]}
                    onChange={(event) => {
                      handleSlider(event, 10, 'social_civil_capital-number_bar_restaurant-^', handleSliderEvent)
                    }}
                  >
                    <RangeSliderTrack>
                      <RangeSliderFilledTrack />
                    </RangeSliderTrack>
                    <RangeSliderThumb index={0} ></RangeSliderThumb>
                    <RangeSliderThumb index={1} />
                  </RangeSlider>
                  <Text fontSize={text} className={'filter-desc'}>Festivals</Text>
                    <Text fontSize={value}>{sliderValues[11][0]} - {sliderValues[11][1]}</Text>
                  <RangeSlider
                    aria-label={['min', 'max']}
                    isDisabled={!premium}
                    min={0}
                    max={100}
                    step={5}
                    colorScheme='gray'
                    defaultValue={[0, 150]}
                    onChange={(event) => {
                      handleSlider(event, 11, 'social_civil_capital-number_festivals-^', handleSliderEvent)
                    }}
                  >
                    <RangeSliderTrack>
                      <RangeSliderFilledTrack />
                    </RangeSliderTrack>
                    <RangeSliderThumb index={0} ></RangeSliderThumb>
                    <RangeSliderThumb index={1} />
                  </RangeSlider>
              <Text as='u' pt={'2'} fontSize={title}>Daily Activities</Text>
                <CheckboxGroup>
                  <Checkbox isDisabled={!premium} onChange={handleCheckboxes} value="social_civil_capital-activities-hike" size='sm'>Hiking Trails</Checkbox>
                  <Checkbox isDisabled={!premium} onChange={handleCheckboxes} value="social_civil_capital-activities-bike" size='sm'>Biking Trails</Checkbox>
                  <Checkbox isDisabled={!premium} onChange={handleCheckboxes} value="social_civil_capital-activities-skiing" size='sm'>Skiing</Checkbox>
                  <Checkbox isDisabled={!premium} onChange={handleCheckboxes} value="social_civil_capital-activities-sea" size='sm'>Seaside</Checkbox>
                  <Checkbox isDisabled={!premium} onChange={handleCheckboxes} value="social_civil_capital-activities-lake" size='sm'>Lakeside</Checkbox>
                  <Checkbox isDisabled={!premium} onChange={handleCheckboxes} value="social_civil_capital-activities-river" size='sm'>Riverside</Checkbox>
                  <Checkbox isDisabled={!premium} onChange={handleCheckboxes} value="social_civil_capital-activities-mountain" size='sm'>Mountains</Checkbox>
                  <Checkbox isDisabled={!premium} onChange={handleCheckboxes} value="social_civil_capital-activities-city*life" size='sm'>Downtown Core</Checkbox>
                  <Checkbox isDisabled={!premium} onChange={handleCheckboxes} value="social_civil_capital-activities-suburb" size='sm'>Suburbia</Checkbox>
                  <Checkbox isDisabled={!premium} onChange={handleCheckboxes} value="social_civil_capital-activities-museum" size='sm'>Notable Museum</Checkbox>
                  <Checkbox isDisabled={!premium} onChange={handleCheckboxes} value="social_civil_capital-activities-landmark" size='sm'>Historical Landmarks</Checkbox>
                </CheckboxGroup>
              </VStack>
          </AccordionPanel>
        </SimpleBarReact>
      </AccordionItem>
    </>
  )
}

export {SocialCivilFilters}