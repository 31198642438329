import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  useDisclosure, Text, Button, Flex, Spacer, Divider
} from '@chakra-ui/react';
import { usePremiumCheckout } from '../../hooks/usePremiumCheckout';
import { UserContext } from '../../Auth/UserContext';
import { useContext } from 'react';

function ModalPremium() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useContext(UserContext);
  const { handleCheckout } = usePremiumCheckout();
 
  const hostname = window.location.hostname;
  const yearly = (hostname === "moveincanada.ca" ? process.env.REACT_APP_STRIPE_YEARLY : process.env.REACT_APP_TEST_STRIPE_YEARLY)
  const monthly = (hostname === "moveincanada.ca" ? process.env.REACT_APP_STRIPE_MONTHLY : process.env.REACT_APP_TEST_STRIPE_MONTHLY)

  return (
    <>
      <Button variant={"ghost"} isExternal={true} style={{width: '170px', float: 'left', marginRight:'10px', backgroundColor: 'white'}} className={'image-hover'}
        onClick={onOpen}
        >Premium ⭐
      </Button>

      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Premium Subscription Options</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex className={"premium-options"} h={'10vh'} lineHeight={'10vh'} textAlign={'center'}>
              <Spacer />
              $12 CAD Monthly
              <Spacer />
              <Divider orientation='vertical' />
              <Spacer />
              $100 CAD Yearly
              <Spacer />
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex w={'100%'}>
              <Spacer />
            <Button className={'image-hover'} variant='outline' borderRadius={'5px'} onClick={() => handleCheckout(user.id, monthly)}>Monthly</Button>
              <Spacer />
            <Button className={'image-hover'} variant='outline' borderRadius={'5px'} onClick={() => handleCheckout(user.id, yearly)}>Yearly</Button>
              <Spacer />
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalPremium;