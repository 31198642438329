import { useState } from "react";



const useSliderValues = function() {
  const [sliderValues, setSliderValues] = useState(
    [['CA$100,000', 'CA$1,500,000'], // 0 house price
      ['CA$300', 'CA$2000'], // 1 studio rent
      ['CA$300', 'CA$2000'], // 2 1-bedroom rent
      ['CA$300', 'CA$2000'], // 3 2-bedroom rent
      [10, 30], // 4 summer temperature
      [-20, 10], // 5 winter temperature
      [0, 50], // 6 museums
      [0, 15], // 7 movie theatres
      [0, 10], // 8 pools
      [0, 50], // 9 parks
      [0, '7,500'], // 10 bars restaurants
      [0, 100], // 11 festivals
      [0, 15], // 12  paid sick leave
      [0, 15], // 13 unpaid sick leave
      [0, 15], // 14 hospitals
      [0, 15], // 15 children hospitals
      [0, "3,000"], // 16 beds
      [0, 200], // 17 clinic density
      [0, 30], // 18 congestion %
      [0, 120], // 19 public commute min
      [0, 250], // 20 bus routes
      [0, 10], // 21 train lines
      [0, 150], // 22 train km
      [0, 1000], // 23 domestic Flights
      [0, 1000], // 24 international Flights
      [0, 100], // 25 France flights
      ['CA$0.00', 'CA$20.00'], // 26 minimum wage
      ['CA$50,000.00', 'CA$150,000.00'], // 27 median household income
      ['CA$50,000.00', 'CA$150,000.00'], // 28 average household income
      [0.0, 15.0], // 29 unemployment %
      ['0', '3,000,000'], // 30 city population
      ['0', '8,000,000'], // 31 urban sprawl population
      [0, '7,500'], // 32 Population density km^2
      [25, 75], // 33 median age
      [0.0, 75.0], // 34 population immigrant %
      [0.00, 100.00] // 35 francophone population %
    ]);
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 0
  });
  const largeFormat = new Intl.NumberFormat();
  const handleSlider = function(event, index, info, handleSliderEvent) {
    if (index === 0 || index === 1 || index === 2 || index === 3 || index === 26 || index === 27 || index === 28) {
      const costs = [currencyFormatter.format(event[0]), currencyFormatter.format(event[1])];
      setSliderValues(prev => {
        const arr = [...prev];
        arr[index] = costs;
        return arr;
      });
    } else {
      const formattedValues = [largeFormat.format(event[0]), largeFormat.format(event[1])];
      setSliderValues(prev => {
        const arr = [...prev];
        arr[index] = formattedValues;
        return arr;
      });
    }
    handleSliderEvent(event, info);
  }
  return { sliderValues, handleSlider }
};
export {useSliderValues};