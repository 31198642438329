//@ts-check
// data that will be unneeded, once a solution for public access to the database is found
import { citiesArray } from './data/baseData'
// react, react-router, and axios
import { BrowserRouter as Router, Link, Route, Routes, unstable_HistoryRouter } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react'
import './styles/App.scss';
import { useLocation, useNavigate } from 'react-router';
// Components
import Footer from './Components/Footer/Index'
import Header from './Components/Header/Index'
import Home from './Components/Home/Index';
import CityDashboard from './Components/CityDashboard/Index';
import { FormPage } from './Components/Account/FormPage';
import Checkout from './Components/Account/Checkout';
// custom hooks and helpers
import { UserContext } from './Auth/UserContext';
import useAuth from './Auth/useAuth';
import { useFormEnabler } from './hooks/useFormEnabler'
import { accountLoginProps, accountSettingsProps, accountRegisterProps } from './helpers/accountForm'
import { makeMarkers } from './helpers/makeMarkers';
import { useFilterState } from './hooks/useFilterState';
import { makeCityRelevanceCards } from './helpers/makeCityRelevanceCards';
import { groupData } from './helpers/groupData';
import { OpenDrawerContext } from './Components/Drawer/OpenDrawerContext';
import { HiddenDrawerContext } from './Components/Drawer/HiddenDrawerContext';
import { useDrawerContext } from './Components/Drawer/Drawers';
// amplify
import Amplify, { API, graphqlOperation, Auth} from 'aws-amplify';
import { listCities, listPremiums } from './graphql/queries';
import awsconfig from './aws-exports';
import useViewport from './hooks/useViewport';
import { Alert, Box, Image } from '@chakra-ui/react';
import { CitySchema }from './Components/SchemaOrg/CitySchema'

Amplify.configure(awsconfig);



const App = function() {
  const [state, setState] = useState({
    cities: [],
    amenities: [],
    demographics: [],
    housing: [],
    health_care: [],
    education: [],
    transportation_infrastructure: [],
    social_civil_capital: [],
    economy: [],
    weather: [],
    filters: {}
  });
  const navigate = useNavigate();
  const { handleCheckboxEvent, loadFilterStates, filterValues, handleSliderEvent } = useFilterState(state);

  // currently unused context, set up early
  const { user, setUser } = useAuth();
  const { open, setOpen } = useDrawerContext();
  const { hidden, setHidden } = useDrawerContext();

  const fetchData = async () => {
    try {
      const cityData = await API.graphql(graphqlOperation(listCities));
      const cityList = cityData.data.listCities.items;
      const stateData = groupData(cityList).groupedData;
      if (user.premium !== undefined && !user.premium) {
        const { demographics, health_care, housing, transportation_infrastructure, social_civil_capital, economy} = stateData;
        for (let i = 0; i < demographics.length; i++ ) {
          stateData.demographics[i] = {
            ...demographics[i],
            population_percent_immigrant: null,
            population_percent_francophone: null,
            med_age: null,
            language_second: null
          };
          stateData.housing[i] = {
            ...housing[i],
            avg_house_price: null,
            avg_rent_studio: null,
            avg_rent_bed_1: null,
            avg_rent_bed_2: null
          };
          stateData.health_care[i] = {
            ...health_care[i],
            active_health_system: null,
            number_hospitals: null,
            number_children_hospitals: null,
            number_beds: null,
            clinic_population_density: null
          };
          stateData.transportation_infrastructure[i] = {
            ...transportation_infrastructure[i],
            km_rail: null,
            rail_lines: null,
            bus_lines: null
          };
          stateData.social_civil_capital[i] = {
            ...social_civil_capital[i],
            number_pool: null,
            number_museum: null,
            number_bar_restaurant: null,
            number_movie_theatre: null,
            number_festivals: null,
            activities: ""
          };
          stateData.economy[i] = {
            ...economy[i],
            med_household_income: null,
            unemployment_rate: null,
            unemployment_year: null,
            economy_sector: ""
          };
          stateData.amenities = [{}]; // remove if amenitiesa are added
        }
      }
      loadFilterStates(stateData.cities, stateData.economy);
      setState((prev) => {
        return {...prev, ...stateData, filters: filterValues}
      })
    } catch (error) {
      console.log('Error on fetch:', error)
      // set basic name and coordinates with data here, currently just a file with
      // names, province, location
      const groupedTempState = groupData(citiesArray, CitySchema)
      loadFilterStates(citiesArray, citiesArray);
      setState(prev => {
        return { ...prev, ...groupedTempState.groupedData, filters: filterValues, schema: [...groupedTempState.schema] }
      })
    }
  }
  const fetchUser = async () => {
    try {
      if (!user.id) {
      const premiumData = await API.graphql(graphqlOperation(listPremiums));
      const premiumList = premiumData.data.listPremiums.items;
      const premiumStatus = premiumList.filter(userId => userId.username === user.username)[0];
      if (premiumStatus) {
        setUser(prev => {
          return {...prev, premium: premiumStatus.premium, id: premiumStatus.id}
        })
      }
      }
    }
    catch (error) {
      console.log("Error on Premium Fetch: ", error)
      // set user.premium to false here, after api is successfully called
    }
  }
  useEffect(() => {
    fetchData();
    fetchUser();
  },[user])
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(res => {
      const userObj = {
        username: res.username,
        premium: false
      }
      setUser(prev => {
        return {...userObj}
      });
    })
    .catch(err => {
      const userObj = {
        id: "",
        username: "",
        premium: false
      };
      setUser(prev => {
        return {...userObj}
      });
      console.log(err, user);
    });
  },[])

  useEffect(() => {
    setState(prev => {
      return {...prev, filters: filterValues}
    })
  }, [filterValues])
  // markers for maps, a pin marker and a city card marker
  // held separately, need to create a hook to show the 
  // city card on pin hover and hold it on pin click,
  // then on card click will send to city page
  // Hold here for Filters
  const mapMarkers = function(markerArg, handleMarkerSelect, handleMarkerHover, removeHandleMarkerHover, selectCity, hoverCity) {
    const handleCardClick = function(city){
      navigate(`/${city}`)
    }
    return makeMarkers(markerArg, handleMarkerSelect, handleMarkerHover, removeHandleMarkerHover, selectCity, hoverCity, handleCardClick);
  };
  const handleCheckboxes = function(event) {
    return handleCheckboxEvent(event)
  }

  const handleCityListCards = function(state, relevance) {
    const handleCardClick = function(city){
      navigate(`/${city}`)
    };
    return makeCityRelevanceCards(state, handleCardClick, relevance)
  }

  // For use for Account creation, login, and account setting popups or pages
  const { disable, handleCancel, handleSubmit } = useFormEnabler(user, setUser);
  const settingsProps = function() {
    return accountSettingsProps(disable, handleCancel, handleSubmit);
  };
  const loginProps = function() {
    console.log("here")
    return accountLoginProps(false, handleCancel, handleSubmit);
  };
  const registerProps = function() {
    return accountRegisterProps(false, handleCancel, handleSubmit);
  };
  const [ resetHeuristicKey, setresetHeuristicKey ] = useState(false);
  const resetFilter = async() => {
    setresetHeuristicKey(!resetHeuristicKey);
    loadFilterStates(state.cities, state.economy);
  }

  const { width, height } = useViewport();
  const breakpoint = 700;
  console.log(state.schema)
  return (
    <div className="body">
    <>
      {/* {state.schema && state.schema} */}
      <OpenDrawerContext.Provider value={{open, setOpen}}>
      <HiddenDrawerContext.Provider value={{hidden, setHidden}}>
      <UserContext.Provider value={{user, setUser}}>
      {breakpoint > width && 
      <Alert >
        <Image className={"warning-icon"} src='images/danger.png' alt='Warning' maxH={'10vh'} />
        <Box>Move in Canada App is not available on mobile.</Box>
        <Box>We are sorry for the inconvenience.</Box>
        <Box>Please reconnect with a larger screen.</Box>
      </Alert>}
      <Header cities={state.cities} />
      <Routes >
        <Route path="/" element={<Home resetHeuristicKey={resetHeuristicKey} resetFilter={resetFilter} mapMarkers={mapMarkers} cities={state.cities} handleCheckboxes={handleCheckboxes} handleSliderEvent={handleSliderEvent} filters={state.filters} handleCityListCards={handleCityListCards} state={state} />} />
        <Route path=":city" element={<Home resetHeuristicKey={resetHeuristicKey} resetFilter={resetFilter} mapMarkers={mapMarkers} cities={state.cities} handleCheckboxes={handleCheckboxes} handleSliderEvent={handleSliderEvent} filters={state.filters} handleCityListCards={handleCityListCards} state={state} />}/>
        <Route path="/checkout/:id" element={<Checkout handleCheckboxes={handleCheckboxes} handleSliderEvent={handleSliderEvent} />} />
        <Route path="/login" element={<FormPage accountProps={loginProps} />}/>
        <Route path="/account-settings" element={<FormPage accountProps={settingsProps}/>}/>
        <Route path="/register" element={<FormPage accountProps={registerProps} />}/>
      </Routes>
      </UserContext.Provider >
      </HiddenDrawerContext.Provider>
      </OpenDrawerContext.Provider>
    </>
    </div>
  );
}

export default App;
