export const citiesArray = [
  {
      "id": 29,
      "order": "Abbotsford",
      "name": "Abbotsford",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "British Columbia",
      "longitude": -122.3054,
      "latitude": 49.0497,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 8,
      "order": "Barrie",
      "name": "Barrie",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Ontario",
      "longitude": -79.6926,
      "latitude": 44.3642,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 28,
      "order": "Brandon",
      "name": "Brandon",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Manitoba",
      "longitude": -99.9522,
      "latitude": 49.834,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 42,
      "order": "Brantford",
      "name": "Brantford",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Ontario",
      "longitude": -80.2592,
      "latitude": 43.1454,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 46,
      "order": "1 Calgary",
      "name": "Calgary",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Alberta",
      "longitude": -114.046,
      "latitude": 51.0079,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 25,
      "order": "Cape Breton Island",
      "name": "Cape Breton Island",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Nova Scotia",
      "longitude": -61.0211,
      "latitude": 46.2479,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 48,
      "order": "Charlottetown",
      "name": "Charlottetown",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Prince Edward Island",
      "longitude": -63.136,
      "latitude": 46.254,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 10,
      "order": "3 Edmonton",
      "name": "Edmonton",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Alberta",
      "longitude": -113.4806,
      "latitude": 53.5416,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 45,
      "order": "2 Fredericton",
      "name": "Fredericton",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "New Brunswick",
      "longitude": -66.6433,
      "latitude": 45.9604,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 11,
      "order": "Guelph",
      "name": "Guelph",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Ontario",
      "longitude": -80.2486,
      "latitude": 43.5392,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 47,
      "order": "Halifax",
      "name": "Halifax",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Nova Scotia",
      "longitude": -63.5752,
      "latitude": 44.6488,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 31,
      "order": "Hamilton",
      "name": "Hamilton",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Ontario",
      "longitude": -79.86,
      "latitude": 43.261,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 33,
      "order": "Iqaluit",
      "name": "Iqaluit",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Nunavut",
      "longitude": -68.52,
      "latitude": 63.7497,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 5,
      "order": "Kelowna",
      "name": "Kelowna",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "British Columbia",
      "longitude": -119.4965,
      "latitude": 49.8829,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 20,
      "order": "Kitchener-Cambridge-Waterloo",
      "name": "Kitchener-Cambridge-Waterloo",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Ontario",
      "longitude": -80.4,
      "latitude": 43.4305,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 38,
      "order": "Lethbridge",
      "name": "Lethbridge",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Alberta",
      "longitude": -112.8391,
      "latitude": 49.696,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 30,
      "order": "London",
      "name": "London",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Ontario",
      "longitude": -81.3887,
      "latitude": 42.949,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 39,
      "order": "Mississauga",
      "name": "Mississauga",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Ontario",
      "longitude": -79.6344,
      "latitude": 43.606,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 16,
      "order": "3 Moncton",
      "name": "Moncton",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "New Brunswick",
      "longitude": -64.7735,
      "latitude": 46.0949,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 4,
      "order": "Montreal",
      "name": "Montreal",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Quebec",
      "longitude": -73.6733,
      "latitude": 45.5479,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 36,
      "order": "2 Moose Jaw",
      "name": "Moose Jaw",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Saskatchewan",
      "longitude": -105.5431,
      "latitude": 50.3985,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 6,
      "order": "Mount Pearl",
      "name": "Mount Pearl",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Newfoundland and Labrador",
      "longitude": -52.815,
      "latitude": 47.5197,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 1,
      "order": "Nanaimo",
      "name": "Nanaimo",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "British Columbia",
      "longitude": -123.9495,
      "latitude": 49.1608,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 9,
      "order": "Oshawa",
      "name": "Oshawa",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Ontario",
      "longitude": -78.8782,
      "latitude": 43.89,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 19,
      "order": "Ottawa",
      "name": "Ottawa",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Ontario",
      "longitude": -75.7086,
      "latitude": 45.4118,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 24,
      "order": "Peterborough",
      "name": "Peterborough",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Ontario",
      "longitude": -78.3253,
      "latitude": 44.2969,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 15,
      "order": "4 Prince Albert",
      "name": "Prince Albert",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Saskatchewan",
      "longitude": -105.7504,
      "latitude": 53.1969,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 35,
      "order": "Prince George",
      "name": "Prince George",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "British Columbia",
      "longitude": -122.7669,
      "latitude": 53.9116,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 26,
      "order": "Prince Rupert",
      "name": "Prince Rupert",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "British Columbia",
      "longitude": -130.3223,
      "latitude": 54.3118,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 40,
      "order": "Quebec",
      "name": "Quebec",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Quebec",
      "longitude": -71.2209,
      "latitude": 46.8168,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 27,
      "order": "2 Red Deer",
      "name": "Red Deer",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Alberta",
      "longitude": -113.8047,
      "latitude": 52.275,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 7,
      "order": "1 Regina:",
      "name": "Regina",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Saskatchewan",
      "longitude": -104.6104,
      "latitude": 50.4549,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 41,
      "order": "Saguenay",
      "name": "Saguenay",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Quebec",
      "longitude": -71.068,
      "latitude": 48.4241,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 3,
      "order": "1 Saint John",
      "name": "Saint John",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "New Brunswick",
      "longitude": -66.0746,
      "latitude": 45.2856,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 2,
      "order": "3 Saskatoon",
      "name": "Saskatoon",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Saskatchewan",
      "longitude": -106.6502,
      "latitude": 52.1328,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 44,
      "order": "Sherbrooke",
      "name": "Sherbrooke",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Quebec",
      "longitude": -71.8941,
      "latitude": 45.4031,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 37,
      "order": "4 St. Albert",
      "name": "St. Albert",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Alberta",
      "longitude": -113.6364,
      "latitude": 53.6447,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 18,
      "order": "1 St. Catherine",
      "name": "St. Catherine",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Ontario",
      "longitude": -79.23,
      "latitude": 43.1814,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 14,
      "order": "St. John's",
      "name": "St. John's",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Newfoundland and Labrador",
      "longitude": -52.7255,
      "latitude": 47.5718,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 22,
      "order": "Sudbury",
      "name": "Sudbury",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Ontario",
      "longitude": -81.00965,
      "latitude": 46.47951,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 12,
      "order": "Thunder Bay",
      "name": "Thunder Bay",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Ontario",
      "longitude": -89.2398,
      "latitude": 48.4207,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 32,
      "order": "2 Toronto",
      "name": "Toronto",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Ontario",
      "longitude": -79.3804,
      "latitude": 43.7184,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 49,
      "order": "Trois-Rivières",
      "name": "Trois-Rivières",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Quebec",
      "longitude": -72.5776,
      "latitude": 46.3582,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 17,
      "order": "2 Vancouver",
      "name": "Vancouver",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "British Columbia",
      "longitude": -123.120735,
      "latitude": 49.23273,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 43,
      "order": "1 Victoria",
      "name": "Victoria",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "British Columbia",
      "longitude": -123.3525,
      "latitude": 48.4395,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 13,
      "order": "Whitehorse",
      "name": "Whitehorse",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Yukon",
      "longitude": -135.0712,
      "latitude": 60.711,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 21,
      "order": "Windsor",
      "name": "Windsor",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Ontario",
      "longitude": -83.029,
      "latitude": 42.3051,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 23,
      "order": "Winnipeg",
      "name": "Winnipeg",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Manitoba",
      "longitude": -97.1522,
      "latitude": 49.8816,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  },
  {
      "id": 34,
      "order": "Yellowknife",
      "name": "Yellowknife",
      "population_city": "Login to learn more",
      "nickname": "",
      "province": "Northwest Territories",
      "longitude": -114.3785,
      "latitude": 62.4612,
      "population_percent_francophone": "Login to learn more",
      "number_pool": "Login to learn more",
      "avg_winter_temp": "Login to learn more",
      "minimum_wage": "Login to learn more",
      "number_sick_unpaid": "Login to learn more",
      "avg_rent_studio": "Login to learn more",
      "congestion_level": "Login to learn more"
  }
];