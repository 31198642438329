import { SimpleGrid, Box } from '@chakra-ui/react'
import { useState } from 'react';
import { buildCityCards } from '../../helpers/buildCityCards';

import { UserContext } from '../../Auth/UserContext';
import { useContext, useEffect} from 'react';

const InfoCards = function(props) {
  const { cityObject, cityName } = props;
  const { user } = useContext(UserContext);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const handleCardClick = function(target) {
    setSelectedCategory(target)
  }
  const handleClose = function() {
    setSelectedCategory(null)
  }

  // is an array of JSX cards, with a header of the category and a body holding the information
  // includes a conditional for statements including "INFORMATION FROM", to place at the bottom of
  // the card
  const cityCards = buildCityCards(user, cityName, cityObject, selectedCategory, handleCardClick, handleClose)
  return (
    <SimpleGrid className="InfoCards-flex" minChildWidth={'250px'} spacingX="2vw" spacingY="2vw">
      {cityCards}
    </SimpleGrid>)
}

export default InfoCards;