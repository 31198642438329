import { AccountDesktop } from './AccountDesktop';
import { AccountMobile } from './AccountMobile';
import AccountMenu from './AccountMenu';
import useViewport from '../../hooks/useViewport';
import { HStack } from "@chakra-ui/react";
import { UserContext } from '../../Auth/UserContext';
import { useContext, useEffect, useState } from 'react';
import ModalPremium from './ModalPremium';

const Account = function() {
  const { user } = useContext(UserContext);
  const { width } = useViewport();
  const [ premLoggedin, setPremLoggedIn ] = useState(false);
  // adjust for mobile responsiveness, current AccountMobile is out of date for styling
  const breakpoint = 0;

  useEffect(() => {
    setPremLoggedIn(user.premium)
    console.log(user.premium)
  }, [user])
  
  return (
    <>
    {
    width < breakpoint ?
    <HStack align="right">
      {(user.username === "") ? <AccountMobile /> :
      <>
        <AccountMenu />
      </>}
    </HStack>
    : <>{(user.username === "") ? <AccountDesktop /> :
    <>
    {!premLoggedin &&
      <ModalPremium />
    }
      <AccountMenu />
    </>}
    </>}
    </>
  )
}
export default Account;