
// refactor to switch statements and functions for readability
// will need to be replaced if database is revamped

const infoDescriptionSelection = function(description, info) {
  if (!info || info === '0.00') {
    return;
  }
  const descriptionArr = description.split('_')

  // not logged in
  if (info === "Login to learn more") {
    return info;
  }

  // language
  if (descriptionArr[0] === 'language') {
    if (descriptionArr[1] === 'first') {
      return `Most Common Language: ${info}`;
    }
    return `Secondary Language: ${info}`;
  }

  // each should be a case in a switch statement and then sent to a function to follow through
  // income and Wage, imagine 
  if (descriptionArr[2] === 'income' || descriptionArr[1] === 'wage') {
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'CAD',
      minimumFractionDigits: 2
    });
    const cost = currencyFormatter.format(info);
    if (descriptionArr[0] === 'avg') {
      return `Average Household Income: ${cost} /year`;
    }
    if (descriptionArr[0] === 'med') {
      return `Median Household Income: ${cost} /year`;
    }
    return `Provincial Minimum Wage: ${cost} /hr`;
  }

  if (descriptionArr[0] === 'clinic') {
    return `Clinic Density: ${info} /km²`;
  }

  if (descriptionArr[1] === 'health') {
    return `Canada has a Universal Health Care Program`;
  }

  // hot economy sector
  if (descriptionArr[0] === 'economy') {
    const infoArray = JSON.parse(info).split("'");
    const str = infoArray[1];
    if (str === '') {
      return;
    }
    return `Economy Hot Sectors: ${str}`;
  }

  // unemployment
  if (descriptionArr[0] === 'unemployment') {
    if (descriptionArr[1] === 'rate') {
      return `Unemployment Rate: ${info}%`;
    }
    return `UNEMPLOYMENT INFORMATION FROM ${info} CENSUS`;
  }

  // every description starting with avg, poor readability and extendability
  if (descriptionArr[0] === 'avg') {

    // rent or house price
    if (descriptionArr[1] === 'rent' || descriptionArr[1] === 'house') {
      const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 0
      });
      const cost = currencyFormatter.format(info);
      if (descriptionArr[3]) {
       return `Average ${descriptionArr[3]} Bedroom Rent: ${cost} /month`;
      }
      if (descriptionArr[2] === 'price'){
        return `Average Cost of a House: ${cost}`;
      }
      return `Average Studio Apartment Rent: ${cost} /month`;
    }

    // temperature
    if (descriptionArr[2] === 'temp') {
      if (descriptionArr[1] === 'winter') {
        return `Average Winter Temperature: ${info} ºC`;
      }
      return `Average Summer Temperature: ${info} ºC`;
    }

    if (descriptionArr[1] === 'daily') {
      return `Average Daily Commute Time: ${info}`;
    }

    // Crime Rate
    if (descriptionArr[1] === 'crime') {
      return `Average City Crime Rate: ${info}%`;
    }
  }

  // better use, but could be a separate function from a switch statement
  if (descriptionArr[0] === 'number') {
    switch (descriptionArr[1]) {
      case 'bar':
        return `Number of Bars And Restaurants: ${info}`;
      case 'movie':
        return `Number of Movie Theatres: ${info}`;
      case 'museum':
        return `Number of Museums: ${info}`;
        case 'pool':
          return `Number of Pools: ${info}`;
        case 'parks':
          return `Number of Parks: ${info}`;
        case 'festivals':
          return `Number of Yearly Festivals: ${info}`;
        case 'french':
          return `Number of French School Programs: ${info}`;
        case 'hospitals':
          return `Number of General Hospitals: ${info}`;
        case 'children':
          return `Number of Children Hospitals: ${info}`;
        case 'sick':
          if (descriptionArr[2] === 'unpaid'){
            return `Yearly Unpaid Sick Leave: ${info} days`;
          }
          return `Yearly Paid Sick Leave: ${info} days`;
        case 'beds':
          return `Total Number of Hospital Beds: ${info}`;
    }
  }

  // same as avg above, should be a switch statement in another function
  if (descriptionArr[0] === 'population') {
    const largeFormat = new Intl.NumberFormat();
    if (descriptionArr[2]) {
      if (descriptionArr[2] === 'francophone') {
        return `Francophone Population: ${info}%`;
      }
      return `Immigrant Population: ${info}%`;
    }
    if (descriptionArr[1] === 'city') {
      const pop = largeFormat.format(info);
      return `City Population: ${pop}`;
    }
    if (descriptionArr[1] === 'density') {
      return `Population Density: ${info} /km²`;
    }
    if (descriptionArr[1] === 'year') {
      return `POPULATION INFORMATION FROM ${info} CENSUS`;
    }
    const pop = largeFormat.format(info);
    return `Metropolitan Population: ${pop}`;
  }

  // amenity density
  if (descriptionArr[1] === 'density') {
    if (descriptionArr[0] === 'groceries') {
      return `Density of Grocery Stores: ${info} /km²`;
    }
    return `Density of Libraries: ${info} /km²`;
  }

  // activities
  if (descriptionArr[0] === 'activities') {
    const infoArray = JSON.parse(info).split("'");
    const str = infoArray[1];
    return `Activities: ${str}`;
  }

  if (descriptionArr[0] === 'km') {
    return `Length of Transit Rail: ${info} km`;
  }
  if (descriptionArr[0] === 'bus') {
    return `Number of Bus Lines: ${info}`;
  }
  if (descriptionArr[0] === 'rail') {
    return `Number of Transit Rail Lines: ${info}`;
  }
  if (descriptionArr[0] === 'congestion') {
    return `Traffic Congestion Levels: ${info}%`;
  }
  if (descriptionArr[0] === 'france') {
    return `Number of Filght Connections to France: ${info}`;
  }
  if (descriptionArr[0] === 'domestic') {
    return `Number of Domestic Filght Connections: ${info}`;
  }
  if (descriptionArr[0] === 'international') {
    return `Number of International Filght Connections: ${info}`;
  }
}

export {infoDescriptionSelection}