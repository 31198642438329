// const grabCityInfo = function(state, cityName) {
//   const cityObject = {[cityName]: []}
//   for (const city in state.cities) {
//     if (state.cities[city]) {
//       if (state.cities[city].name === cityName) {
//         const cityId = state.cities[city].id;
//         cityObject[cityName].push({cities: state.cities[city]})
//         for (const category in state) {
//           const categoryIndex = cityId - 1;
//           if(state[category][categoryIndex]) {
//             if (state[category][categoryIndex].city_id === cityId) {
//               cityObject[cityName].push({[category]: state[category][categoryIndex]});
//             }
//           }
//         }
//       }
//     }
//   }
//   return cityObject;
// }

const grabCityInfo = function(state, cityName) {
  const cityObject = {[cityName]: []};
  for (const category in state) {
    if(state[category][1] && category !== 'amenities'){ // remove second condition when amenities has info
      for (const i of state[category]) {
        if (i.name === cityName) {
          cityObject[cityName].push({[category]: i});
        }
      }
    }
  }
  return cityObject
}

export {grabCityInfo};