import {
  VStack, Text, Heading,
  MenuButton, MenuList,
  Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton,
  RangeSlider, RangeSliderThumb, RangeSliderTrack, RangeSliderFilledTrack, Button
 } from '@chakra-ui/react';
 import { useContext } from 'react';
 import { UserContext } from '../../../Auth/UserContext';
 import SimpleBarReact from "simplebar-react";
 
 import "simplebar/src/simplebar.css";


const InfastructureFilters = function(props) {
  const { user } = useContext(UserContext);
  const { sliderValues, handleSliderEvent, handleSlider, sizes } = props;
  const premium = (user ? (user.premium ? true : false) : false);
  const freemium = (user ? true : false);
  const { heading, title, text, value } = sizes;
  return (
  <>
    <AccordionItem w={'100%'}>
      <AccordionButton size={heading} >
        <Heading  className={'filter-heading'} flex='1' textAlign='left'  size={heading}>Transport</Heading>
        <AccordionIcon />
      </AccordionButton>
      <SimpleBarReact style={{maxHeight: '30vh'}} >
        <AccordionPanel>
          <VStack mb={2}>
            <Text as='u' fontSize={title}>Automobile:</Text>
            <Text fontSize={text} className={'filter-desc'}>Congestion</Text>
            <Text fontSize={value}>{sliderValues[18][0]} - {sliderValues[18][1]}%</Text>
            <RangeSlider
              aria-label={['min', 'max']}
              isDisabled={!freemium}
              min={0}
              max={30}
              step={1}
              colorScheme='gray'
              defaultValue={[0, 30]}
              onChange={(event) => {
                handleSlider(event, 18, 'transportation_infrastructure-congestion_level-^', handleSliderEvent)
              }}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} ></RangeSliderThumb>
              <RangeSliderThumb index={1} />
            </RangeSlider>
            <Text as='u' paddingTop={'2'} fontSize={title}>Public Transport</Text>
            <Text fontSize={text} className={'filter-desc'}>Average Commute Time</Text>
            <Text fontSize={value}>{sliderValues[19][0]} - {sliderValues[19][1]} Minutes</Text>
            <RangeSlider
              aria-label={['min', 'max']}
              isDisabled={!freemium}
              min={0}
              max={120}
              step={15}
              colorScheme='gray'
              defaultValue={[0, 120]}
              onChange={(event) => {
                handleSlider(event, 19, 'transportation_infrastructure-avg_daily_transport_time-^', handleSliderEvent)
              }}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} ></RangeSliderThumb>
              <RangeSliderThumb index={1} />
            </RangeSlider>
            <Text fontSize={text} className={'filter-desc'}>Number of Bus Routes</Text>
            <Text fontSize={value}>{sliderValues[20][0]} - {sliderValues[20][1]}</Text>
            <RangeSlider
              aria-label={['min', 'max']}
              isDisabled={!freemium}
              min={0}
              max={250}
              step={5}
              colorScheme='gray'
              defaultValue={[0, 250]}
              onChange={(event) => {
                handleSlider(event, 20, 'transportation_infrastructure-bus_lines-^', handleSliderEvent)
              }}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} ></RangeSliderThumb>
              <RangeSliderThumb index={1} />
            </RangeSlider>
            <Text fontSize={text} className={'filter-desc'}>Number of Train Lines</Text>
            <Text fontSize={value}>{sliderValues[21][0]} - {sliderValues[21][1]}</Text>
            <RangeSlider
              aria-label={['min', 'max']}
              isDisabled={!freemium}
              min={0}
              max={10}
              step={1}
              colorScheme='gray'
              defaultValue={[0, 10]}
              onChange={(event) => {
                handleSlider(event, 21, 'transportation_infrastructure-rail_lines-^', handleSliderEvent)
              }}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} ></RangeSliderThumb>
              <RangeSliderThumb index={1} />
            </RangeSlider>
            <Text fontSize={text} className={'filter-desc'}>Train Rail Distance</Text>
            <Text fontSize={value}>{sliderValues[22][0]} - {sliderValues[22][1]} km</Text>
            <RangeSlider
              aria-label={['min', 'max']}
              isDisabled={!freemium}
              min={0}
              max={150}
              step={1}
              colorScheme='gray'
              defaultValue={[0, 150]}
              onChange={(event) => {
                handleSlider(event, 22, 'transportation_infrastructure-km_rail-^', handleSliderEvent)
              }}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} ></RangeSliderThumb>
              <RangeSliderThumb index={1} />
            </RangeSlider>
          </VStack>
        </AccordionPanel>
      </SimpleBarReact>
    </AccordionItem>
  </>
  )
};

export { InfastructureFilters };


// Available when flight info is in the database
// simple paste abotve the </VStack> above

            // <Text fontSize={title}>Public Flights (Coming Soon)</Text>
            // <Text fontSize={text} className={'filter-desc'}>Weekly Domestic Flightss</Text>
            // <Text fontSize={value}>{sliderValues[23][0]} - {sliderValues[23][1]}</Text>
            // <RangeSlider
            //   aria-label={['min', 'max']}
            //   isDisabled={true}
            //   min={0}
            //   max={1000}
            //   step={20}
            //   colorScheme='gray'
            //   defaultValue={[0, 1000]}
            //   onChange={(event) => {
            //     handleSlider(event, 23, 'transportation_infrastructure-domestic_connections-^', handleSliderEvent)
            //   }}
            // >
            //   <RangeSliderTrack>
            //     <RangeSliderFilledTrack />
            //   </RangeSliderTrack>
            //   <RangeSliderThumb index={0} ></RangeSliderThumb>
            //   <RangeSliderThumb index={1} />
            // </RangeSlider>
            // <Text fontSize={text} className={'filter-desc'}>Weekly International Flights</Text>
            // <Text fontSize={value}>{sliderValues[24][0]} - {sliderValues[24][1]} km</Text>
            // <RangeSlider
            //   aria-label={['min', 'max']}
            //   isDisabled={true}
            //   min={0}
            //   max={1000}
            //   step={20}
            //   colorScheme='gray'
            //   defaultValue={[0, 1000]}
            //   onChange={(event) => {
            //     handleSlider(event, 24, 'transportation_infrastructure-international_connections-^', handleSliderEvent)
            //   }}
            // >
            //   <RangeSliderTrack>
            //     <RangeSliderFilledTrack />
            //   </RangeSliderTrack>
            //   <RangeSliderThumb index={0} ></RangeSliderThumb>
            //   <RangeSliderThumb index={1} />
            // </RangeSlider>
            // <Text fontSize={text} className={'filter-desc'}>Weekly Flights to France</Text>
            // <Text fontSize={value}>{sliderValues[25][0]} - {sliderValues[25][1]}</Text>
            // <RangeSlider
            //   aria-label={['min', 'max']}
            //   isDisabled={true}
            //   min={0}
            //   max={100}
            //   step={5}
            //   colorScheme='gray'
            //   defaultValue={[0, 100]}
            //   onChange={(event) => {
            //     handleSlider(event, 25, 'transportation_infrastructure-france_flights-^', handleSliderEvent)
            //   }}
            // >
            //   <RangeSliderTrack>
            //     <RangeSliderFilledTrack />
            //   </RangeSliderTrack>
            //   <RangeSliderThumb index={0} ></RangeSliderThumb>
            //   <RangeSliderThumb index={1} />
            // </RangeSlider>