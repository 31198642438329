import {
  VStack, Text, Heading,
  MenuButton, MenuList,
  Checkbox,
  Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton,
  RangeSlider, RangeSliderThumb, RangeSliderTrack, RangeSliderFilledTrack, Button
 } from '@chakra-ui/react';
 import { useContext } from 'react';
 import { UserContext } from '../../../Auth/UserContext';
 import SimpleBarReact from "simplebar-react";
 
 import "simplebar/src/simplebar.css";


const HealthCareFilters = function(props) {
  const { user } = useContext(UserContext);
  const { sliderValues, handleSliderEvent, handleSlider, handleCheckboxes, sizes } = props;
  const premium = (user ? (user.premium ? true : false) : false);
  const freemium = (user ? true : false);
  const { heading, title, text, value } = sizes;
  return (
  <>
    <AccordionItem w={'100%'}>
    <AccordionButton size={heading} >
        <Heading className={'filter-heading'} flex='1' textAlign='left'  size={heading}> Health Care</Heading>
        <AccordionIcon />
    </AccordionButton>
      <SimpleBarReact style={{maxHeight: '30vh'}} >
        <AccordionPanel>
          <VStack mb={2}>
            <Text as='u' paddingTop={'2'} fontSize={title}>Provincial</Text>
            <Checkbox isDisabled={!premium} onChange={handleCheckboxes} value="health_care-active_health_system-bool" size={'sm'}>Active Health System</Checkbox>
            <Text fontSize={text} className={'filter-desc'}>Paid Sick Leave</Text>
            <Text fontSize={value}>{sliderValues[12][0]} - {sliderValues[12][1]}</Text>
            <RangeSlider
              aria-label={['min', 'max']}
              isDisabled={!freemium}
              min={0}
              max={15}
              step={1}
              colorScheme='gray'
              defaultValue={[0, 15]}
              onChange={(event) => {
                handleSlider(event, 12, 'health_care-number_sick_paid-^', handleSliderEvent)
              }}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} ></RangeSliderThumb>
              <RangeSliderThumb index={1} />
            </RangeSlider>
            <Text fontSize={text} className={'filter-desc'}>Unpaid Sick Leave</Text>
            <Text fontSize={value}>{sliderValues[13][0]} - {sliderValues[13][1]}</Text>
            <RangeSlider
              aria-label={['min', 'max']}
              isDisabled={!freemium}
              min={0}
              max={15}
              step={1}
              colorScheme='gray'
              defaultValue={[0, 15]}
              onChange={(event) => {
                handleSlider(event, 13, 'health_care-number_sick_unpaid-^', handleSliderEvent)
              }}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} ></RangeSliderThumb>
              <RangeSliderThumb index={1} />
            </RangeSlider>
            <Text as='u' paddingTop={'2'} fontSize={title}>Hospitals</Text>
            <Text fontSize={text} className={'filter-desc'}>Hospitals</Text>
            <Text fontSize={value}>{sliderValues[14][0]} - {sliderValues[14][1]}</Text>
            <RangeSlider
              aria-label={['min', 'max']}
              isDisabled={!freemium}
              min={0}
              max={15}
              step={1}
              colorScheme='gray'
              defaultValue={[0, 15]}
              onChange={(event) => {
                handleSlider(event, 14, 'health_care-number_hospitals-^', handleSliderEvent)
              }}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} ></RangeSliderThumb>
              <RangeSliderThumb index={1} />
            </RangeSlider>
          </VStack>
        </AccordionPanel>
      </SimpleBarReact>
    </AccordionItem>
  </>
  )
};

export { HealthCareFilters };

// Available for when clinic density, children's hospital,
// or hospital beds is in the database
// simple paste the block of code above the </VStack> above

/* <Text fontSize={text} className={'filter-desc'}>Clinic Density (Coming Soon)</Text>
<Text fontSize={value}>{sliderValues[17][0]} - {sliderValues[17][1]}</Text>
<RangeSlider
  aria-label={['min', 'max']}
  isDisabled={false}
  min={0}
  max={200}
  step={1}
  colorScheme='gray'
  defaultValue={[0, 200]}
  onChange={(event) => {
    handleSlider(event, 17, 'health_care-number_children_hospitals-^', handleSliderEvent)
  }}
>
  <RangeSliderTrack>
    <RangeSliderFilledTrack />
  </RangeSliderTrack>
  <RangeSliderThumb index={0} ></RangeSliderThumb>
  <RangeSliderThumb index={1} />
</RangeSlider> */


{/* <Text fontSize={text} className={'filter-desc'}>Children Hospitals</Text>
<Text fontSize={value}>{sliderValues[15][0]} - {sliderValues[15][1]}</Text>
<RangeSlider
  aria-label={['min', 'max']}
  isDisabled={!freemium}
  min={0}
  max={15}
  step={1}
  colorScheme='gray'
  defaultValue={[0, 15]}
  onChange={(event) => {
    handleSlider(event, 15, 'health_care-number_children_hospitals-^', handleSliderEvent)
  }}
>
  <RangeSliderTrack>
    <RangeSliderFilledTrack />
  </RangeSliderTrack>
  <RangeSliderThumb index={0} ></RangeSliderThumb>
  <RangeSliderThumb index={1} />
</RangeSlider> */}


{/* <Text fontSize={text} className={'filter-desc'}>Beds</Text>
<Text fontSize={value}>{sliderValues[16][0]} - {sliderValues[16][1]}</Text>
<RangeSlider
  aria-label={['min', 'max']}
  isDisabled={!freemium}
  min={0}
  max={3000}
  step={1}
  colorScheme='gray'
  defaultValue={[0, 3000]}
  onChange={(event) => {
    handleSlider(event, 16, 'health_care-number_beds-^', handleSliderEvent)
  }}
>
  <RangeSliderTrack>
    <RangeSliderFilledTrack />
  </RangeSliderTrack>
  <RangeSliderThumb index={0} ></RangeSliderThumb>
  <RangeSliderThumb index={1} />
</RangeSlider> */}