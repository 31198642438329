//@ts-check

const groupData = function(dataArray, CitySchema) {
  const groupedData = {
    cities: [],
    amenities: [],
    demographics: [],
    economy: [],
    health_care: [],
    social_civil_capital: [],
    weather: [],
    housing: [],
    education: [],
    transportation_infrastructure: [],
    filters: {}
  };
  let count = 0;
  const schema = [];
  for (const city of dataArray) {
    count++;
    const cities = {
      id: count,
      order: city.id,
      name: city.name,
      sponsored: city.sponsored,
      nickname: city.nickname,
      province: city.province,
      population_city: city.population_city,
      population_urban: city.population_urban,
      population_year: city.population_year,
      summary: city.summary,
      longitude: city.longitude,
      latitude: city.latitude
    };
    groupedData.cities.push(cities);
    const demographics = {
      name: city.name,
      population_density: city.population_density,
      med_age: city.med_age,
      population_percent_immigrant: city.population_percent_immigrant,
      population_percent_francophone: city.population_percent_francophone,
      language_first: city.language_first,
      language_second: city.language_second
    };
    groupedData.demographics.push(demographics);
    const social_civil_capital = {
      name: city.name,
      number_pool: city.number_pool,
      number_parks: city.number_parks,
      number_museum: city.number_museum,
      number_movie_theatre: city.number_movie_theatre,
      number_bar_restaurant: city.number_bar_restaurant,
      number_festivals: city.number_festivals,
      activities: city.activities
    };
    groupedData.social_civil_capital.push(social_civil_capital);
    const weather = {
      name: city.name,
      avg_winter_temp: city.avg_winter_temp,
      avg_summer_temp: city.avg_summer_temp
    }
    groupedData.weather.push(weather);
    const economy = {
      name: city.name,
      minmium_wage: city.minmium_wage,
      avg_household_income: city.avg_household_income,
      med_household_income: city.med_household_income,
      unemployment_rate: city.unemployment_rate,
      economy_sector: city.economy_sector,
      unemployment_year: city.unemployment_year,
    }
    groupedData.economy.push(economy);
    const health_care = {
      name: city.name,
      active_health_system: city.active_health_system,
      number_hospitals: city.number_hospitals,
      number_children_hospitals: city.number_children_hospitals,
      number_beds: city.number_beds,
      number_sick_paid: city.number_sick_paid,
      number_sick_unpaid: city.number_sick_unpaid,
      clinic_population_density: city.clinic_population_density
    };
    groupedData.health_care.push(health_care);
    const amenities = {
      name: city.name,
      library_density: city.library_density,
      groceries_density: city.groceries_density,
      avg_crime_rate: city.avg_crime_rate
    };
    groupedData.amenities.push(amenities);
    const housing = {
      name: city.name,
      avg_rent_studio: city.avg_rent_studio,
      avg_rent_bed_1: city.avg_rent_bed_1,
      avg_house_price: city.avg_house_price,
      avg_rent_bed_2: city.avg_rent_bed_2
    };
    groupedData.housing.push(housing);
    const transportation_infrastructure = {
      name: city.name,
      congestion_level: city.congestion_level,
      avg_daily_transport_time: city.avg_daily_transport_time,
      bus_lines: city.bus_lines,
      rail_lines: city.rail_lines,
      km_rail: city.km_rail,
      domestic_connections: city.domestic_connections,
      international_connections: city.international_connections,
      france_flights: city.france_flights
    };
    groupedData.transportation_infrastructure.push(transportation_infrastructure);

    // add schema.org data here
    if (CitySchema) {
      schema.push(<CitySchema name={city.name} longitude={city.longitude} latitude={city.latitude} province={city.province} />)
    }
  }
  groupedData.cities.sort((a, b) => {
    const nameA = a.order;
    const nameB = b.order;
    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }
    return 0;
  })
  return { groupedData, schema };
}

export { groupData }