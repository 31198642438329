const Legend = function() {
  return (
    <div className="Legend-container">
      <div className="Legend-flex">
        <div className="Legend-list">
        </div>
      </div>
    </div>);;
}

export default Legend;