//@ts-check

import { UserContext } from '../../Auth/UserContext';
import { useContext, useEffect} from 'react';
import { NavDrawer } from '../Drawer/Drawers'
import { Box, Heading } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { useNavigate, useParams } from 'react-router';

// GraphQl
import { updatePremium, createPremium } from '../../graphql/mutations'
import Amplify, { API, graphqlOperation} from 'aws-amplify';



const Checkout = function(props) {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const parameters = {
    id: user.id,
    premium: true
  }
  useEffect(() => {
    const authUser = async() => {
      try {
        await API.graphql(graphqlOperation(updatePremium, { input: parameters }));
        await setUser (prev => {
          return {...prev, premium: true}
        })
        navigate('/')
      }
      catch (error) {
        console.log("Error updating user: ", error)
      }
    }
    setTimeout(() => {
    authUser();
    }, 1000);
  },[])
  const {state, mapMarkers, cities, handleCheckboxes, handleSliderEvent, filters, handleCityListCards} = props;
  return (
    <Box w={'100vw'} h={'100vh'} >
      <NavDrawer handleCheckboxes={handleCheckboxes} handleSliderEvent={handleSliderEvent} />
      <Heading textAlign={'center'}>Please wait while we update your user experience <Spinner /></Heading>
      
    </Box>
  )
}

export default Checkout;