import { Box, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react'
import { buildCityAccordion } from '../../helpers/buildCityAccordion';

const InfoAccordion = function(props) {
  const { cityObject, cityName} = props;
  const accordionArray = buildCityAccordion(cityName, cityObject);
  return (
  <Accordion allowToggle className="InfoAccordion-flex">
    {accordionArray}
  </Accordion >
  )
}

export default InfoAccordion;