import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from './UserContext'

const useAuth = function() {
  const [user, setUser] = useState(UserContext);

  return { user, setUser }
}

export default useAuth;