//@ts-check

import { Auth } from "aws-amplify";

const logout = async() => {
  try {
    await Auth.signOut();
    window.location.reload(false);
  }
  catch (err) {
    console.log('error signing out ', err);
  }
}

export default logout;