import { useEffect, useState } from "react";
import { SimpleGrid, VStack } from "@chakra-ui/react"
import { makeCityRelevanceCards } from "../../helpers/makeCityRelevanceCards";

const CityList = function(props) {
  const { cities, filters, state, handleCityListCards, relevance } = props;
  const [ cityCards, setCityCards ] = useState([])

  useEffect(() => {
    setCityCards(handleCityListCards(state, relevance))
  }, [cities, filters, relevance]);
  
  return (
    <div className="CityList-container">
      <div className="CityList-flex">
        <div className="CityList-list">
          <SimpleGrid pl={'4'} spacingX={'2vw'} spacingY={'2vw'} minChildWidth={'250px'}>
          {cityCards}
          </SimpleGrid>
        </div>
      </div>
    </div>);;
}

export default CityList;