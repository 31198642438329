import { useContext, useEffect, useState } from 'react';
import ReactMapGL from 'react-map-gl';
import { UserContext } from '../../Auth/UserContext';
import { useFilterState } from '../../hooks/useFilterState';
import { useMapHover } from '../../hooks/useMapHover';
import useViewport from '../../hooks/useViewport';
import {useParams} from 'react-router-dom';


const InteractiveMap = function(props) {
  const { user, setUser } = useContext(UserContext);
  const { mapMarkers, cities, filters } = props;
  const { width, height } = useViewport();
  const { hoverPosition, handleHover } = useMapHover();
  const params = useParams();
  const [ lastCityParams, setLastCityParams ] = useState();
  const [ markerArray, setMarkerArray ] = useState([]);
  const [ selectCity, setSelectCity ] = useState('');
  const [ hoverCity, setHoverCity ] = useState('Toronto');
  const [ mapView, setMapView ] = useState({
    latitude: 57.02191819129598,
    longitude: -102.00255645728863,
    width: `${width}px`,
    height: `${height}`,
    zoom: 3,
    pitch: 0
  })
  const handleMarkerSelect = function(event) {
    const city = cities.filter(city => city.name === event.target.className)[0];
    if (selectCity !== city) {
      setSelectCity(city.name)
      setMapView(prev => {
        return {
        ...prev,
        latitude: city.latitude,
        longitude: city.longitude,
        width: `${width}px`,
        height: `${height}`,
        pitch: 0
        };
      });
    }
  }
  const handleMarkerHover = function(event) {
    const city = event.target.className;
    if (!selectCity) {
      setHoverCity(city)
    }
  }
  const removeHandleMarkerHover = function(event) {
    if (hoverCity) {
      setHoverCity(null)
    }
  }
  useEffect(() => {
    let paramCity;
    if (cities[1] && params.city !== lastCityParams) {
      paramCity = cities.filter(city => city.name === params.city)[0];
      setLastCityParams(params.city);
      setSelectCity(params.city)
      setMapView(prev => {
        return (paramCity ?
        {
          ...prev,
          latitude: paramCity.latitude,
          longitude: paramCity.longitude,
          width: `${width}px`,
          height: `${height}`,
          zoom: 6,
          pitch: 0
        } : {
          ...prev,
          latitude: 57.02191819129598,
          longitude: -102.00255645728863,
          width: `${width}px`,
          height: `${height}`,
          zoom: 3,
          pitch: 0
        }
      )});
    }
    const markerArg = {cities, filters};
    setMarkerArray(mapMarkers(markerArg, handleMarkerSelect, handleMarkerHover, removeHandleMarkerHover, selectCity, hoverCity));
  },[filters, params]);
  useEffect(() => {
    const markerArg = {cities, filters}
    setMarkerArray(mapMarkers(markerArg, handleMarkerSelect, handleMarkerHover, removeHandleMarkerHover, selectCity, hoverCity));
  },[selectCity, hoverCity])
  useEffect(() => {
    setMapView(prev => ({...prev, width: `${width}px`, height: `${height}px`}));
  }, [height, width])
  const handleViewportChange = function(viewport) {
    if (viewport.latitude < 42) {
      viewport.latitude = 42.1
    }
    if (viewport.longitude < -140) {
      viewport.longitude = -139.8
    }
    if (viewport.longitude > -53) {
      viewport.longitude = -53.2
    }
    setMapView(viewport);
  }
  return (
    <div className="InteractiveMap-container">
      <div className="InteractiveMap-flex">
        <div className="InteractiveMap-map">
          <ReactMapGL
            {...mapView}
            mapStyle="mapbox://styles/luke-canada-talents/ckz4l69cp001y15o3t03pvlag"
            mapboxApiAccessToken={process.env.REACT_APP_MAP_API}
            onViewportChange={handleViewportChange}
            minZoom={3}
            maxZoom={8}
            maxPitch={0}
            bearing={0}
            maxBounds={[-51.670, 41.719], [-141.353, 83.690]}
            onHover={event => handleHover(event)}
            onClick={() => setSelectCity(null)}
            >
            {markerArray}
          </ReactMapGL>
          <div className={'hover-position'}>Longitude: {hoverPosition[0]} Latitude: {hoverPosition[1]}</div>
        </div>
      </div>
    </div>);;
}

const InteractiveMapSmall = function(props) {
  const { user, setUser } = useContext(UserContext);
  const { mapMarkers, cities, filters } = props;
  const { width, height } = useViewport();
  const { hoverPosition, handleHover } = useMapHover();
  const [ markerArray, setMarkerArray ] = useState([]);
  const [ selectCity, setSelectCity ] = useState('Vancouver');
  const [ hoverCity, setHoverCity ] = useState(null);
  const [mapView, setMapView] = useState({
    latitude: 60.02191819129598,
    longitude: -97.00255645728863,
    width: `${width}px`,
    height: `${height}px`,
    zoom: 2.25,
    pitch: 0
  })
  const handleMarkerSelect = function(event) {
    const city = event.target.className;
    if (selectCity === city) {
      setSelectCity(null)
    }
    if (selectCity !== city) {
      setSelectCity(city)
    }
  }
  const handleMarkerHover = function(event) {
    const city = event.target.className;
    if (!selectCity) {
      setHoverCity(city)
    }
  }
  const removeHandleMarkerHover = function(event) {
    if (hoverCity) {
      setHoverCity(null)
    }
  }
  useEffect(() => {
    const markerArg = {cities, filters}
    setMarkerArray(mapMarkers(markerArg, handleMarkerSelect, handleMarkerHover, removeHandleMarkerHover));
  },[cities, filters])
  useEffect(() => {
    const markerArg = {cities, filters}
    setMarkerArray(mapMarkers(markerArg, handleMarkerSelect, handleMarkerHover, removeHandleMarkerHover, selectCity, hoverCity));
  },[selectCity, hoverCity])
  useEffect(() => {
    setMapView(prev => ({...prev, width: `${width}px`, height: `${height}px`}))
  }, [height, width])
  const handleViewportChange = function(viewport) {
    if (viewport.latitude < 41.719) {
      viewport.latitude = 41.8
    }
    if (viewport.longitude < -141.353) {
      viewport.longitude = -141.2
    }
    if (viewport.longitude > -51.670) {
      viewport.longitude = -51.7
    }
    setMapView(viewport);
  }
  return (
    <div className="InteractiveMapSmall-container">
      <div className="InteractiveMapSmall-flex">
        <div className="InteractiveMapSmall-map">
          <ReactMapGL
            {...mapView}
            mapStyle="mapbox://styles/luke-canada-talents/ckz4l69cp001y15o3t03pvlag"
            mapboxApiAccessToken={process.env.REACT_APP_MAP_API}
            onViewportChange={handleViewportChange}
            minZoom={2.25}
            maxZoom={5}
            maxPitch={0}
            bearing={0}
            maxBounds={[-51.670, 41.719], [-141.353, 83.690]}
            onHover={event => handleHover(event)}
            onClick={() => setSelectCity(null)}
            backgroundColor={'18204e'}
            >
            {markerArray}
          </ReactMapGL>
        </div>
      </div>
    </div>);;
}

export { InteractiveMap, InteractiveMapSmall };