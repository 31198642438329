import {infoDescriptionSelection} from '../helpers/infoDescriptionSelection'

const filterBy = function(filterType, data, dataName, range) {
  
  if (filterType === '<') {
    const citySort = Object.entries(data).sort((a, b) => a[1] - b[1]);
    const finalFilter = {};
    let filterRef = 1;
    for (const sortedCityData of citySort) {
      const filterInfo = infoDescriptionSelection(dataName, sortedCityData[1])
      finalFilter[sortedCityData[0]] = {filterRef, active: 0, info: filterInfo};
      filterRef++;
    }
    return {...finalFilter};
  }
  if (filterType === '>') {
    const citySort = Object.entries(data).sort((a, b) => b[1] - a[1]);
    const finalFilter = {};
    let filterRef = 1;
    for (const sortedCityData of citySort) {
      const info = infoDescriptionSelection(dataName, sortedCityData[1])
      finalFilter[sortedCityData[0]] = {filterRef, info, active: 0};
      filterRef++;
    }
    return {...finalFilter};
  }
  if (filterType === '^') {
    const min = range[0];
    const max = range[1];
    const finalFilter = {};
    for (const city in data) {
      if ((data[city]) === undefined) {
        const info = `${dataName} information is not available.`;
        finalFilter[city] = {filterRef: 0, active: 0, info};
      } else if ((data[city] >= min && data[city] <= max)) {
        const info = infoDescriptionSelection(dataName, data[city]);
        finalFilter[city] = {filterRef: 0, active: 0, info};
      } else {
        const info = infoDescriptionSelection(dataName, data[city]);
        finalFilter[city] = {filterRef: 0, active: 1, info};
      }
    }
    return {...finalFilter};
  }
  if (filterType) {
    const finalFilter = {};
    for (const city in data) {
      const actionPhrase = filterType.split('*').join(' ');
      if (actionPhrase === "bool") {
        const active = (data[city] ? 0 : 1)
        const info = infoDescriptionSelection(dataName, data[city]);
        finalFilter[city] = {filterRef: 0, active, info};
      } else {
        const infoArray = JSON.parse(data[city]).split("'");
        const filteredPhrase = infoArray.includes(actionPhrase);
        if (filteredPhrase) {
          const info = infoDescriptionSelection(dataName, data[city]);
          finalFilter[city] = {filterRef: 0, active: 0, info};
        } else {
          const info = infoDescriptionSelection(dataName, data[city]);
          finalFilter[city] = {filterRef: 0, active: 1, info};
        }
      }
    }
    return finalFilter
  }
}

const filterInfo = function(state, value, range) {
  const filtersArr = value.split('-');
  const filteredData = {};
  for (const cat of state[filtersArr[0]]) {
    const cityName = cat.name;
    filteredData[cityName] = cat[filtersArr[1]];
  }
  return filterBy(filtersArr[2], filteredData, filtersArr[1], range)
}

export { filterInfo }