import { Box, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Heading, Text } from '@chakra-ui/react'
import { displayHeader, infoDescriptions } from './buildCityCards';

const buildCityAccordion = function(city, cityObject) {
  const categoryArr = cityObject[city];
  let key = 0;
  if (categoryArr[1]) {
    const displayInfo = categoryArr.map((categoryObj) => {
      key++;
      let category = Object.keys(categoryObj)[0];
      const displayInfo = infoDescriptions(categoryObj[category]); 
      const header = displayHeader(category, 'sm')
      return (
        <AccordionItem key={key}>
          <AccordionButton >
            <Box flex="1">{header}</Box >
            <AccordionIcon />
          </AccordionButton >
          <AccordionPanel >
            <Box >{displayInfo}</Box>
          </AccordionPanel >
        </AccordionItem >
      );
    });
    return (
      <>
      {displayInfo}
      </>
    ) 
  }
  return (
    <>
    <Heading>Sorry We Don't Carry Information For:</Heading>
    <Heading>{city}</Heading>
    <Text>If you think we should include information for {city}, or</Text>
    <Text>if you found this page in error, please contact the adminstrator</Text>
    </>
  );
};

export { buildCityAccordion };