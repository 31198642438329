import { Button, Link } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"

const AccountMobile = function() {
  return (
    <>
      <Button>
        <Link as={NavLink} to="/Register" >Create Account</Link>
      </Button>
      <Button>
        <Link as={NavLink} to="/Login" >Login</Link>
      </Button>
    </>
  )
}

export { AccountMobile};