import {
  VStack, Text, Heading,
  Checkbox, CheckboxGroup,
  Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton,
  RangeSlider, RangeSliderThumb, RangeSliderTrack, RangeSliderFilledTrack
 } from '@chakra-ui/react';


const AmenitiesFilters = function(props) {
  const { sliderValues, handleSliderEvent, handleSlider } = props;
  return (<></>);
};

export { AmenitiesFilters };