import {
  VStack, Text, Heading,
  MenuButton, MenuList,
  Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton,
  RangeSlider, RangeSliderThumb, RangeSliderTrack, RangeSliderFilledTrack, Button
 } from '@chakra-ui/react';
 import { useContext } from 'react';
 import { UserContext } from '../../../Auth/UserContext';
 import SimpleBarReact from "simplebar-react";
 
 import "simplebar/src/simplebar.css";


const HousingFilters = function(props) {
  const { user } = useContext(UserContext);
  const { sliderValues, handleSliderEvent, handleSlider, sizes } = props;
  const premium = (user ? (user.premium ? true : false) : false);
  const { heading, title, text, value } = sizes;

  // Includes code for a 2 bedroom rent filter below
  return (
    <>
    <AccordionItem w={'100%'}>
      <AccordionButton size={heading} >
        <Heading  className={'filter-heading'} flex='1' textAlign='left'  size={heading}> Housing</Heading>
        <AccordionIcon />
      </AccordionButton>
      <SimpleBarReact style ={{maxHeight: '30vh'}} >
      <AccordionPanel>
              <VStack mb={2}>
              <Text as='u' pt={'2'} fontSize={title}>Price of a House:</Text>
                <Text fontSize={value}>{sliderValues[0][0]} - {sliderValues[0][1]}</Text>
                <RangeSlider
                  aria-label={['min', 'max']}
                  isDisabled={!premium}
                  min={100000}
                  max={1500000}
                  step={50000}
                  colorScheme='gray'
                  defaultValue={[100000, 1500000]}
                  onChange={(event) => {
                    handleSlider(event, 0, 'housing-avg_house_price-^', handleSliderEvent)
                  }}
                >
                  <RangeSliderTrack>
                  <RangeSliderFilledTrack />
                  </RangeSliderTrack>
                  <RangeSliderThumb index={0} ></RangeSliderThumb>
                  <RangeSliderThumb index={1} />
                </RangeSlider>
              <Text as='u' pt={'2'} fontSize={title}>Price of Rent</Text>
                <Text fontSize={text} className={'filter-desc'}>Studio Apartment</Text>
                <Text fontSize={value}>{sliderValues[1][0]} - {sliderValues[1][1]}</Text>
                <RangeSlider
                  aria-label={['min', 'max']}
                  isDisabled={!premium}
                  min={300}
                  max={2000}
                  step={50}
                  colorScheme='gray'
                  defaultValue={[300, 2000]}
                  onChange={(event) => {
                    handleSlider(event, 1, 'housing-avg_rent_studio-^', handleSliderEvent)
                  }}
                >
                  <RangeSliderTrack>
                    <RangeSliderFilledTrack />
                  </RangeSliderTrack>
                  <RangeSliderThumb index={0} ></RangeSliderThumb>
                  <RangeSliderThumb index={1} />
                </RangeSlider>
                  <Text fontSize={text} className={'filter-desc'}>1 Bedroom Appartment</Text>
                    <Text fontSize={value}>{sliderValues[2][0]} - {sliderValues[2][1]}</Text>
                  <RangeSlider
                    aria-label={['min', 'max']}
                    isDisabled={!premium}
                    min={300}
                    max={2000}
                    step={50}
                    colorScheme='gray'
                    defaultValue={[300, 2000]}
                    onChange={(event) => {
                      handleSlider(event, 2, 'housing-avg_rent_bed_1-^', handleSliderEvent)
                    }}
                  >
                    <RangeSliderTrack>
                      <RangeSliderFilledTrack />
                    </RangeSliderTrack>
                    <RangeSliderThumb index={0} ></RangeSliderThumb>
                    <RangeSliderThumb index={1} />
                  </RangeSlider>
                </VStack>
      </AccordionPanel>
      </SimpleBarReact>
      </AccordionItem>
    </>

  )
};

export { HousingFilters };

// Available when 2 bedroom has data, if it ever does,
// simply place above the </VStack> just above

// <Text ontSize={text} className={'filter-desc'}>2 Bedroom Appartment</Text>
// <Text fontSize={value}>{sliderValues[3][0]} - {sliderValues[3][1]}</Text>
// <RangeSlider
// aria-label={['min', 'max']}
// isDisabled={!premium}
// min={300}
// max={2000}
// step={50}
// colorScheme='gray'
// defaultValue={[300, 2000]}
// onChange={(event) => {
//   handleSlider(event, 3, 'housing-avg_rent_bed_2-^', handleSliderEvent)
// }}
// >
// <RangeSliderTrack>
//   <RangeSliderFilledTrack />
// </RangeSliderTrack>
// <RangeSliderThumb index={0} ></RangeSliderThumb>
// <RangeSliderThumb index={1} />
// </RangeSlider>