import { useState } from 'react';
import { filterInfo } from '../helpers/filterInfo';

const useFilterState = function(state) {
  const [currentFilters, setCurrentfilters] = useState({
    example: {
      active: false,
      'Example': {
        filterRef: 2,
        active: 0,
        info: 'Population 3000'
      }
    }
  });
  const [filterValues, setFilterValues] = useState({
    "Example": {
      filterRef: 5,
      active: 1,
      info: ['Hope City', 'Plenty of Hiking']
    }
  });

  const loadFilterStates = function(cities, economyData) {
    const changeFilterValues = filterValues;
    for (const city of cities) {
      const largeFormat = new Intl.NumberFormat().format(city.population_city);
      const economy = economyData.filter(cityEco => cityEco.name === city.name)[0];
      const unemployment = (economy.unemployment_rate ? `${economy.unemployment_rate}%` : 'Premium Info' )
      changeFilterValues[city.name] = {
        filterRef: 0,
        active: 0,
        info: [`Population: ${largeFormat}`,`Unemployment Rate: ${unemployment}`]
      }
      if (largeFormat === "NaN") {
        console.log(largeFormat)
        changeFilterValues[city.name] = {
          filterRef: 0,
          active: 0,
          info: [`Login to Learn more`]
        }
      }
    }
    setFilterValues(prev => {
      return {...changeFilterValues}
    })
  }

  const addFilter = function(cityFilterObj, thisPreviousFilter) {
    const changeEachFilter = {};
    for (const city in cityFilterObj) {
      if (city !== 'active') {
        let changeFilterRef = filterValues[city].filterRef;
        let changeFilterActive = filterValues[city].active;
        let changeFilterInfo = filterValues[city].info;
        if (cityFilterObj[city].filterRef > 0) {
          changeFilterRef += cityFilterObj[city].filterRef;
        }
        if (
          (cityFilterObj[city].active === 1 && thisPreviousFilter === true)
          ||
          (thisPreviousFilter !== true && thisPreviousFilter[city].active === 0 && cityFilterObj[city].active === 1)
          ){
          changeFilterActive += 1;
        }
        if (!changeFilterInfo.includes(cityFilterObj[city].info)) {
          changeFilterInfo.push(cityFilterObj[city].info);
        }
        changeEachFilter[city] = {filterRef: changeFilterRef, active: changeFilterActive, info: changeFilterInfo};
      }
    }
    // markerUpdate()
    setFilterValues(prev => {
      return {...prev, ...changeEachFilter};
    })
    console.log(filterValues)
    return filterValues;
  }

  const removeFilter = function(cityFilterObj, thisPreviousFilter) {
    const changeEachFilter = {};
    for (const city in cityFilterObj) {
      if (city !== 'active') {
        let changeFilterRef = filterValues[city].filterRef;
        let changeFilterActive = filterValues[city].active;
        let changeFilterInfo = filterValues[city].info;
        if (cityFilterObj[city].filterRef > 0) {
          changeFilterRef -= cityFilterObj[city].filterRef;
        }
        if (
          (cityFilterObj[city].active === 1 && thisPreviousFilter === true)
          ||
          (thisPreviousFilter && thisPreviousFilter !== true && thisPreviousFilter[city].active === 1 && cityFilterObj[city].active === 0)
          ){
          changeFilterActive -= 1;
        }
        const removeIndex = changeFilterInfo.indexOf(cityFilterObj[city].info);
        changeFilterInfo.splice(removeIndex, 1)
        changeEachFilter[city] = {filterRef: changeFilterRef, active: changeFilterActive, info: changeFilterInfo};
      }
    }
    // markerUpdate()
    setFilterValues(prev => {
      return {...prev, ...changeEachFilter};
    })
    return filterValues;
  }

  const handleSliderEvent = function(event, info) {
    const filterByCity = filterInfo(state, info, event);
    if (currentFilters[info]) {
      for (const city in currentFilters[info]) {
        if (city !== 'active') {
          if (filterByCity[city].active < currentFilters[info][city].active) {
            removeFilter(filterByCity, currentFilters[info]);
            break;
          }
          if (filterByCity[city].active > currentFilters[info][city].active) {
            addFilter(filterByCity, currentFilters[info]);
            break;
          }
        }
      }
    } else {
      addFilter(filterByCity, true)
    }
    setCurrentfilters(prev => {
      return {...prev, [info]: {active: true, ...filterByCity}}
    });
  }

  const handleCheckboxEvent = function(event) {
    const value = event.target.value;
    if (currentFilters[value]) {
      // activate or deactivate the current filter
      const changeCurrentFilter = currentFilters[value];
      changeCurrentFilter.active = !currentFilters[value].active;
      setCurrentfilters(prev => {
        return {...prev, ...changeCurrentFilter}
      });
      if (currentFilters[value].active) {
        return addFilter(currentFilters[value], true)
      } else {
        const filtersArr = value.split('-');
        if (filtersArr[2] === '<' || filtersArr[2] === '>') {
          return removeFilter(currentFilters[value], false)
        }
        return removeFilter(currentFilters[value], true);
      }
    }
    // If the filter does not exist yet add to currentFilters
    const filterByCity = filterInfo(state, value);
    filterByCity.active = true;
    setCurrentfilters(prev => {
      return {...prev, [value]: filterByCity}
    })
    return addFilter(filterByCity, true)
  }

  return {handleCheckboxEvent, handleSliderEvent, filterValues, loadFilterStates};
}

export {useFilterState}